* {
  margin: 0;
  padding: 0;
}
.password {
  background-color: #257abf;
   
  height: 100vh;
}
 
 
 
.password-col {
  width: 50%;
  background-color: white;
  height: 450px;
  border-radius: 20px;
  padding: 30px;
  position: relative;
  margin: auto;
  top:120px;
}

.password-form {
  margin-left: 15%;
  margin-top: 10px;
}
.password-box{
  display: flex;
  margin-top: 5px;
}
.backward-p{
  margin-left: 5%;
  margin-top: 20px;
  font-size: 28px;
}
.password-txt{
  color: #257ABF;
  font-size: 26px;
  font-weight: 700;
  margin-left: 32%;
  margin-top: -30px;
}
.password-form label {
  margin-top: 16px;
}
.password-ph {
  margin-top: 10px;
  border: none !important;
  background-color: #363740;
  width: 77%;
  padding: 9px !important;
  border-radius: 7px;
  color: white;
}
.password-form a {
  text-decoration: none;
  margin-left: 198px;
  color: #257ABF;
  font-size: 12.5px;
  font-weight: 600;
}
.confirm-btn {
  margin-left: 58%;
  border: none;
  width: 80px;
  height: 30px;
cursor: pointer;
  border-radius: 10px;
  background-color: #257ABF;
  color: white;
  margin-top: 15px;
  font-size: 12px;
}
.otp-box{
  margin-left: 15%;
}
.otp{
  width: 38px !important;
  height: 35px;
  margin-top: 10px;
  background-color: #363740;
  border: none;
  border-radius: 9px;
  color: white;
  padding: 15px;
  
}
.continue-btn {
  margin-left: 40%;
  border: none;
  width: 100px !important;
  height: 35px;
  padding: 5px;
  border-radius: 10px;
  background-color:#257ABF;
  color: white;
  margin-top: 5px;
  font-weight: 600;
  cursor: pointer;
}
@media (max-width:800px){
  .password-col{
    width: 80%;
  }
  .otp{
    padding-left: 0px!important;
    width: 38px !important;
    height:12px !important;
    
  }
 
}
/* @media screen and (max-width: 1370px){
  .password
  {height:700px;}

}


@media screen and (max-width: 1280px){ */
/* 
  .otp{
      margin-left: 25px !important;
      width: 20px !important;
      height:20px !important;
      padding-left: 10px!important;
  } */
  /* .password
  {
      height:800px;
      
  }


}


@media screen and (max-width: 1000px){ */

  /* .otp{
      padding-left: 5px!important;
      width: 10px !important;
      height:12px !important;
  } */
  /* .password
  {
      height:900px;
      
      
  }
}


@media screen and (max-width: 766px)
{
  /* .otp{
      width:30px !important;
  } */

  /* .password-col
  {
      width:300px;
      margin-left: 50px;
  }


}



@media screen and (max-width: 650px)
{
  .password-col{
      width: 350px !important;
      height: 600px !important;
      margin-left: 70px!important;
  }
}  */