.material-delete-icon {
  font-size: 19px;
  cursor: pointer;
}
.material-delete-icon {
  font-size: 20px;
  margin-right: 6px !important;
  cursor: pointer;
}
.icon-div {
  margin-left: 40px;
}
.material-adding-input {
  border: none;
  width: 170px !important;
  margin-left: 18px;
  border-radius: 1px;
}

.material-table-head {
  text-align: center;
  padding: 13px !important;
  min-width: 14rem;
}

.meterial-td {
  text-align: center;
  padding: 10px;
}

.material-table-border {
  margin-top: 60px !important;
  margin-left: 200px !important;
  width: 320px !important;
}

.material-head {
  background-color: #257abf !important ;
  color: white !important;
 padding: 50px;
}

@media only screen and (max-width: 750px) {
  .material-table-border {
  
    margin-top: 20% !important;
    width: 150px !important;
  }
  .material-adding-input {
    border: none;
    width: 130px !important;
    margin-left: 2px;
    border-radius: 1px;
    font-size: 13px !important;
  }

  .material-table-head {
    padding: 10px !important;
  }

  .meterial-td {
    padding: 9px;
    font-size: 15px !important;
  }
  
  .table-th-content {
    font-size: 16px !important;
  }
}
/* //rolescss */
.head {
  margin-left: 3vw !important;
}
.icon-div {
  margin-left: 40px;
}
.delete-icon {
  font-size: 19px;
  cursor: pointer;
}
.edit-icon {
  font-size: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.adding-input {
  border: none;
  width: 160px !important;
  border-radius: 1px;
  padding: 10px !important;
}

.table-head {
  text-align: center;
  padding: 13px !important;
}

.stock-td {
  text-align: center;
  padding: 10px !important;
}
.add-btn {
  margin-left: 60%;
}

.table-border {
  margin-top: 60px !important;
  

  width: 340px !important;
}

.role-head {
  background-color: #257abf !important ;
  color: white !important;
}
.cancle-btn {
  width: 70px;
  height: 40px;
  background-color:rgb(244, 46, 46);
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
  
}
.blue-btn-new{
  width: 70px;
  height: 40px;
  background-color: #326bff;
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
}

@media only screen and (max-width: 800px) {
  .add-btn {
    margin-right:-60%;
  }
  .table-border {
    margin-top: 60px !important;
    margin-left: 6px !important;
    width:60% !important;
  }
}

.table-border-manage-stock{
  width:500px !important;
  margin-top: 100px !important;
  margin: auto;

}

.cancel-btn-stock{
  width:100px !important;
}

/* Buttons */
.filled-btn {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 6px 14px;
  border-radius: 8px;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.filled-btn:hover {
  background-color: #2563eb;
}

.outlined-btn {
  background-color: transparent;
  color: #374151;
  border: 1px solid #d1d5db;
  padding: 6px 14px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.outlined-btn:hover {
  background-color: #f3f4f6;
}

/* Icons */
.hover-scale {
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  margin: 0 8px;
}

.hover-scale:hover {
  transform: scale(1.15);
  color: #2563eb;
}

/* Table */
.table-border-manage-stock {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.stock-td {
  background-color: #f9fbff;
  border-radius: 8px;
  padding: 10px;
}

.adding-input {
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 14px;
}

.form-field {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d1d5db;
  border-radius: 1px;
  font-size: 14px;
  background-color: #ffffff;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.form-field:focus {
  border-color: #3b82f6;
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

.form-field::placeholder {
  color: #9ca3af;
  font-weight: 400;
}

.centered {
  text-align: center;
}

.centered::placeholder {
  text-align: center;
}