/* start body bark mode css  */

body {
    transition: all .5s ease-in-out;
}

body.dark {
    background-color: $black;

    .header-section .hrader-topbar .logo .logo-on {
        transition: all 0.5s ease-in-out;
        display: none;
    }

    .header-section .hrader-topbar .logo .logo-two {
        transition: all 0.5s ease-in-out;
        display: block;
    }

    .header-section .side-menu {
        background: $small-black;
    }

    .header-section .side-menu ul li a {
        color: $white;
    }

    .about-section,
    .service-section,
    .portfolio-section,
    .testimonial-section,
    .funfact-section,
    .blog-section,
    .contact-section,
    .footer-section {
        background: $small-black;
    }

    .about-section .image {
        background: #2B2B2B;
    }

    .about-section .image .white-img {
        display: none;
        transition: all 0.5s ease-in-out;
    }

    .about-section .image .dark-img {
        display: block;
        transition: all 0.5s ease-in-out;
        display: block;
    }

    .about-section .content h2 {
        color: $white;
        transition: all 0.5s ease-in-out;
    }

    .about-section .content h3 {
        color: $white;
        transition: all 0.5s ease-in-out;
    }

    .about-section .content p {
        color: #ccc;
        transition: all 0.5s ease-in-out;
    }

    .progras-card svg circle {
        stroke: #414141;
        transition: all 0.5s ease-in-out;
    }

    .progras-card svg circle:last-of-type {
        stroke: $white;
        transition: all 0.5s ease-in-out;
    }


    .progras-card .number h3 {
        color: $white;
        transition: all 0.5s ease-in-out;
    }

    .progras-card .title h2 {
        color: $white;
        transition: all 0.5s ease-in-out;
    }


    .section-title h2 {
        transition: all 0.5s ease-in-out;
        color: $white;
    }

    .section-title h3 {
        transition: all 0.5s ease-in-out;
        color: $white;
    }

    .service_item {
        transition: all 0.5s ease-in-out;
        border: 1px solid #474747;
    }

    .service_item .content h2 {
        transition: all 0.5s ease-in-out;
        color: $white;
    }

    .service_item .content p {
        color: #CCC;
        transition: all 0.5s ease-in-out;
    }

    .service_item .icon {
        background: #2A2A2A;
    }

    .service_item .icon .white-img {
        display: none;
        transition: all 0.5s ease-in-out;
    }

    .service_item .icon .black-img {
        display: block;
        transition: all 0.5s ease-in-out;
    }

    .portfolio-category ul li button {
        color: #CCC;
        border: 1px solid #555353;
    }

    .portfolio_card h2 {
        color: $white;
        transition: all 0.5s ease-in-out;
    }

    .testimonial-card {
        border: 1px solid #474747;
        transition: all 0.5s ease-in-out;
    }

    .testimonial-card .top-content .image {
        border: 2px solid #3A3A3A;
        transition: all 0.5s ease-in-out;
    }

    .testimonial-card .top-content .text h2 {
        color: $white;
        transition: all 0.5s ease-in-out;
    }

    .testimonial-card .top-content .text span {
        color: $white;
        transition: all 0.5s ease-in-out;
    }

    .testimonial-card h3 {
        color: #CCC;
        transition: all 0.5s ease-in-out;
    }

    .testimonial-wrap .owl-prev,
    .testimonial-wrap .owl-next {
        color: #B6B6B6;
        border: 1px solid #4E4D4D;
        background: #1B1B1B;
        transition: all 0.5s ease-in-out;
    }

    .testimonial-wrap .owl-prev:hover,
    .testimonial-wrap .owl-next:hover {
        background: #2A2A2A;
        transition: all 0.5s ease-in-out;
    }

    .testimonial-wrap .slick-prev,
    .testimonial-wrap .slick-next {
        background: #1B1B1B;
        border-color: #474747;
    }

    .funfact-section .item h2 {
        color: $white;
        transition: all 0.5s ease-in-out;
    }

    .funfact-section .item h4 {
        color: #ccc;
        transition: all 0.5s ease-in-out;
    }


    .blog-wrap .item .content {
        transition: all 0.5s ease-in-out;
        border: 1px solid #474747;
        background: #2A2A2A;
    }

    .blog-wrap .item .content .date li {
        color: #ccc;
        transition: all 0.5s ease-in-out;
        font-size: 18px;
    }

    .blog-wrap .item .content .date li:last-child::before {
        transition: all 0.5s ease-in-out;
        background: #ccc;
    }

    .blog-wrap .item .content h2 {
        color: $white;
    }


    .blog-wrap .item .content h4 {
        color: $white;
    }

    .contact-info .info-item .text h2 {
        color: $white;
    }

    .contact-info .info-item .text span {
        color: #ccc;
    }

    .contact-info .info-item .icon {
        background: #2A2A2A;
    }

    .contact-info .info-item .icon .white-img {
        transition: all 0.5s ease-in-out;
        display: none;
    }

    .contact-info .info-item .icon .black-img {
        transition: all 0.5s ease-in-out;
        display: inline-block;
    }

    .contact-form-area {
        border: 1px solid #474747;
        background: #2A2A2A;
        transition: all 0.5s ease-in-out
    }

    .contact-form-area form input,
    .contact-form-area form select,
    .contact-form-area form textarea {
        border-color: #474747;
    }


    .contact-form-area form textarea {
        border: 1px solid #474747;
        transition: all 0.5s ease-in-out
    }

    .contact-form-area {

        form input,
        form select,
        form textarea {
            color: $white;
        }

        form {
            ::-webkit-input-placeholder {
                /* Edge */
                font-style: normal;
                color: $white;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                font-style: normal;
                color: $white;
            }

            ::placeholder {
                font-style: normal;
                color: $white;
            }


        }

        .nice-select {
            color: $white;
        }

        .form-control:focus {
            color: $white;
        }
    }

    .footer-section {
        p {
            color: $white;
        }
    }

    .footer-section {
        ul {

            li {
                a {

                    color: $white;

                    &::before {
                        background: $white;
                    }
                }

            }
        }
    }




    .switcher-open {
        background: #2A2A2A;
        transition: all 0.5s ease-in-out
    }

    .color-bar {
        background: #2A2A2A;
        transition: all 0.5s ease-in-out
    }

    .color-bar button {
        background: #1B1B1B;
        transition: all 0.5s ease-in-out
    }

    .header-section.sticky_active {

        @media (max-width: 991px) {
            background: #303030;
        }
    }

    .cursor {
        border: 1.1px solid $light;
    }

    .MuiDialog-paperScrollPaper {
        background-color: #1B1B1B !important;
    }

    .modal-body {
        background: #1B1B1B;
    }

    .quickview-dialog .MuiButtonBase-root {
        background: #1B1B1B;
    }


    // wpo-service-single-area

    .wpo-service-single-area {
        background: #1B1B1B;

        h2,
        h3 {
            color: $white;
        }

        p {
            color: #ddd;
        }

        ul li {
            color: #ddd;
        }

        .wpo-service-area {
            padding-bottom: 0;

            .wpo-service-item {
                border: 1px solid $border-dark;

                i {
                    background: $white;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    margin: 0 auto;
                    display: block;
                    border-radius: 50%;

                    img {
                        max-width: 30px;
                    }
                }

                .fi {
                    color: $body-color;
                }
            }
        }

        .wpo-contact-title {
            h2 {
                color: $white;
            }

            p {
                color: #ddd;
            }
        }

        .wpo-service-contact-area {
            background: #1B1B1B;
            border: 1px solid $border-dark;

            .wpo-contact-form-area {


                .form-control {
                    background: #242424;
                    color: $light;
                    border: 0;
                    padding: 15px;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    &::-webkit-input-placeholder {
                        /* Edge */
                        color: $white;
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: $white;
                    }

                    &::placeholder {
                        color: $white;
                    }
                }

                select.form-control {
                    background: #242424 url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
                }

                .submit-area {
                    button {
                        border-radius: 6px;
                        color: $white;
                        background: $text-color;
                    }
                }

            }
        }
    }


    // wpo-project-single-area

    .wpo-project-single-area {
        background: #1B1B1B;

        h2,
        h3 {
            color: $white;
        }

        p {
            color: #ddd;
        }

        ul li {
            color: #ddd;
        }

        .wpo-project-single-wrap {
            .wpo-project-single-item {
                .wpo-project-single-content-des-right {
                    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
                    background: #242424;

                    ul {

                        li {
                            color: $white;

                            span {
                                color: $body-color;
                            }
                        }
                    }
                }

            }

            .wpo-project-single-item-quote {
                background: #2c2b2b;

                p {
                    color: $light;
                }

                span {
                    color: $body-color;

                    span {
                        color: $text-color;
                    }
                }
            }

            .wpo-contact-title {
                h2 {
                    color: $white;
                }

                p {
                    color: #ddd;
                }
            }

            .wpo-project-text {
                span {
                    color: $white;
                }
            }

            .wpo-project-contact-area {
                background: #1B1B1B;
                border: 1px solid $border-dark;

                .wpo-contact-form-area {


                    .form-control {
                        background: #242424;
                        color: $light;
                        border: 0;
                        padding: 15px;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }

                        &::-webkit-input-placeholder {
                            /* Edge */
                            color: $white;
                        }

                        &:-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: $white;
                        }

                        &::placeholder {
                            color: $white;
                        }
                    }

                    select.form-control {
                        background: #242424 url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
                    }

                    .submit-area {
                        button {
                            border-radius: 6px;
                            color: $white;
                            background: $text-color;
                        }
                    }

                }
            }
        }
    }


    // wpo-blog-single-section

    .wpo-blog-single-section {
        background: #1B1B1B;

        h2,
        h3 {
            color: $white;
        }

        p {
            color: #ddd;
        }

        ul li {
            color: #ddd;
        }

        .entry-meta {

            ul {
                li {
                    color: $light;

                    a {
                        color: $light;

                        &:hover {
                            color: $text-color;
                        }
                    }

                }
            }

        }

        .post {
            blockquote {
                background-color: #333;
                color: $light;

                &:before {
                    border: 2px solid $border-dark;
                    background: #333;

                }


            }
        }


        /*** tag-share ***/
        .tag-share,
        .tag-share-s2 {
            border-bottom: 1px solid $border-dark;
            color: $heading-color;

            .tag {

                >span {
                    font-family: $heading-font;
                    color: $white;
                }

                li {
                    position: relative;
                    color: $light;
                }


                a {
                    color: $body-color;
                    background: #333;

                    &:hover {
                        color: $text-color;
                    }
                }
            }


        }

        .tag-share-s2 {

            .tag {
                a {
                    color: $text-color;
                    background: none;
                }
            }
        }


        /*** author-box ***/
        .author-box {

            .author-name {
                font-family: $heading-font;
                color: $white;
            }

            .social-link a {
                color: $body-color;
            }

            .social-link a:hover {
                color: $text-color;
            }
        }

        /*** more-posts ***/
        .more-posts {
            border-color: $border-dark;

            .next-post {
                border-left: 1px solid $border-dark;
            }

            .previous-post {

                .post-control-link {
                    color: $white;
                }
            }

            .post-control-link {
                color: $text-color;
            }

            .post-name {
                color: $light;
            }
        }

        /*** comments area ***/
        .comments-area {

            li>div {
                border-bottom: 1px solid $border-dark;
            }


            .comments-meta {

                h4 {
                    color: $white;
                }

                h4 span {
                    color: $text-color;
                }
            }

            .comment-reply-link {
                color: $body-color;

                &:hover {
                    color: $text-color;
                }
            }
        }


        /*** comment-respond ***/
        .comment-respond {

            form input,
            form textarea {
                background-color: #333;
                border: 1px solid $border-dark;
                color: $body-color;

                @include media-query(991px) {
                    height: 40px;
                }
            }

            .form-submit input {
                background-color: $text-color;
                color: $white;
            }
        }
    }

}