/*--------------------------------------------------------------
2. header
--------------------------------------------------------------*/
.header-section {
	padding: 40px 0 25px;
	@media (max-width:991px) {
		padding:30px 0 20px;
	}

	&.sticky_active {
		@media (max-width:991px) {
			box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
			position: fixed;
			top: 0;
			width: 100%;
			background: #F2F5F8;
			transition: all 0.5s;
			z-index: 11;
		}
	}

	.sticky_active {
		transition: all 0.5s;
	}

	.hrader-topbar {

		.logo {
			position: fixed;
			cursor: pointer;

			@media (max-width:991px) {
				position: unset;
			}

			.logo-on {
				transition: all .3s ease-in-out;
			}

			.logo-two {
				transition: all .3s ease-in-out;
				display: none;
			}
		}

		.menu-open {
			cursor: pointer;
			position: relative;
			margin-left: auto;
			text-align: center;
			width: 40px;
			height: 40px;
			line-height: 50px;
			background: $light;

			i {
				font-size: 24px;
			}

			@media (min-width:992px) {
				display: none;
			}
		}
	}

	.side-menu {
		position: fixed;
		top: 0;left: 0;
		z-index: 11;
		padding: 0;
		background-color: $white;
		border-radius: 0px;
		transition: all 0.5s;
		width: auto;
		overflow: hidden;

		@media (max-width:1399px) {
			width: 232px;
		}
		@media (max-width:575px) {
			width: 240px;
			padding: 33px 20px;
		}

		.menu-close {
			display: none;
		}

		@media (max-width:991px) {
			box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
			left: -100%;
			height: 100%;
			top: 0;
			border-radius:0px;
			overflow-x: hidden;
			overflow-y: scroll;
			padding-top: 80px;

			.menu-close {
				position: absolute;
				right: 15px;
				top: 15px;
				display: block;
				z-index: 99;
				background: none;
				color: $text-color;
				text-align: center;
				width: 40px;
				height: 40px;
				line-height: 40px;
				border: 1px solid $border-color;
			}
		}

		&.open-mobilemenu {
			left: 0;
		}

		ul {
			list-style: none;

			li {
				padding: 10px 0;
				transition: all .5s ease-in-out;

				a {
					color: $black;
					text-align: center;
					font-family: $heading-font;
					font-size: 18px;
					font-weight: 600;
					text-transform: uppercase;
					position: relative;
					display: block;
					text-align: left;
					cursor: pointer;
				
					@media (max-width:575px) {
						font-size: 16px;
					}

					&::before {
						position: absolute;
						left: 100px;
						top: 49%;
						width: 180px;
						height: 2px;
						content: '';
						background: $border-color;
						transform: scaleY(-50%);
						transition: all .5s ease-in-out;
						opacity: 0;
						visibility: hidden;

					}


					&:hover,
					&.active {
						&::before {
							opacity: 1;
							visibility: visible;
							left: 0;
						}
					}

				}
			}
		}
	}
}