p{
  margin-bottom: 0 !important;
}

.form-input {
  background-color:white;
  padding-left: 30px;
  border: 2px solid #a7a7a7;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  width:85%;
}
th{
  font-size: 18px !important;
}
td{
  font-size: 16px !important;
}
input:disabled {
  background-color: #f0f0f0; /* Light gray */
  color:black; /* Dim text */
  cursor: not-allowed; /* Show "not allowed" cursor */
}

textarea:disabled {
  background-color: #f0f0f0; /* Light gray */
  color: black; /* Dim text */
  cursor: not-allowed; /* Show "not allowed" cursor */
}


.form-input-select:disabled {
  background-color: #f0f0f0; /* Light gray */
  color: black; /* Dim text */
  cursor: not-allowed; /* Show "not allowed" cursor */
}
.form-input-select {
  background-color:white;
  border: 2px solid #a7a7a7;
width:85%;
  padding: 10px;
  margin-top: 10px;

  color: black;

  border-radius: 5px;
}

.form-head {
  background-color: #257abf;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 11px;
  
}


.blue-btn {
  width: 100px;
  height: 40px;
  background-color: #257abf;
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
  text-align: center;
  
}

.black-btn {
  width: 120px;
  height: 40px;
  background-color: #1b2431;
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.table-th {
  background-color: #257abf;
  color: white;

}
.blue-txt{
  color: #257abf;
}
/* input[type=date]
{
  width:85% !important;
} */

.table-th-content {
  /* padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px; */
  padding: 10px !important;
  font-weight: bolder;
   
  border: 1px solid white !important;

  white-space: pre;
}

tr:nth-child(even) {
  background-color: #eef7ff;
  color: black;
}
.export{
  margin-left: 30% !important;
}
.table-search {
  width: 140px;
  height: 38px;
  padding-left: 10px;
  border-radius: 5px;
  border: 2px solid #a7a7a7;
}


.table-search-date {
  padding-left: 10px;
}

.dropdown-option-color {
  background-color: #c8e6ff;
}

.orange-card {
  background-color: #ffa770;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  color: #7b6512;
  height: 50px;
  width: auto;
  text-align: center;
 padding-top: 18px;
  margin-bottom: 5px;
  font-weight:bolder;
  text-align: center;

  
  
}

.orange-card p{
  font-size: 16px !important;
  line-height: 1;
}
.green-card {
  background-color: #b0e9bd;
  color: #2c693a;
  height: 50px;
  width: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-align: center;
  padding-top: 18px;
  margin-bottom: 5px;
  font-weight:bolder;
}

.green-card p{
  font-size: 16px !important;
  line-height: 1;
}

.pink-card {
  background-color: #ffa5ee;
  color: #a73535;
  height: 50px;
  width: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-align: center;
 padding-top: 18px;
  margin-bottom: 5px;
  font-weight:bolder;
}

.pink-card p{
  font-size: 16px !important;
  line-height: 1;
}

.red-card {
  background-color: #f07c7c;
  color: #a73535;
  height: 50px;
  width: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
 padding-top: 18px;
  margin-bottom: 5px;
  font-weight:bolder;
}

.red-card p{
  font-size: 16px !important;
  line-height: 1;
}

.purple-card {
  background-color: #ccb7ff;
  color: #5b4591;
  height: 50px;
  width: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-align: center;
  font-weight:bolder;
  /*padding-top: 18px;
    margin-bottom: 5px; */
}

.purple-card p{
  font-size: 16px !important;
  line-height: 1;
}

.brown-card {
  background-color: #cfa87b;
  color: #663815;
  height: 50px;
  width: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-align: center;
 padding-top: 18px;
  margin-bottom: 5px;
  font-weight:bolder;
}
.brown-card p{
  font-size: 16px !important;
  line-height: 1;
}

.blue-card {
  background-color: #7dabff;
  color: #2e5caf;
  height: 50px;
  width: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-align: center;
 padding-top: 18px;
  margin-bottom: 5px;
  font-weight:bolder;
}

.blue-card p{
  font-size: 16px !important;
  line-height: 1;
}

.yellow-card {
  background-color: #fbdc6e;
  color: rgb(94, 94, 14);
  height: 50px;
  width: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-align: center;
 padding-top: 18px;
  margin-bottom: 5px;
  font-weight:bolder;
}
.yellow-card p{
  font-size: 16px !important;
  line-height: 1;
}

.grey-card {
  background-color: #a7a7a7;
  color: black;
  height: 50px;
  width: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-align: center;
 padding-top: 18px;
  margin-bottom: 5px;
  font-weight:bolder;
}

.grey-card p{
  font-size: 16px !important;
  line-height: 1;
}

.dropdown-btn {
  background-color: #c8e6ff;
  color: white;
  border: 2px solid #a7a7a7;
}
.cancle-btn {
  width: 70px;
  height: 40px;
  background-color:rgb(244, 46, 46);
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
  
}
.blue-btn-new{
  /* width: 70px; */
  height: 40px;
  background-color: #326bff;
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
}