.row-btn {
    display: flex;
    justify-content: space-around;
  }
  .row-btn1 {
    display: flex;
    margin-left: -10px;
  }
  .row-btn2 {
    display: flex;
  }
  .row-btn1 button {
    margin-left: 11px;
  
    cursor: pointer;
  }
  .row-btn2 button {
    margin-left: 10px;
  }

  /* .staff-margin-left{
    margin-left: 2% !important;

  } */
  
  /* .staff {
    margin-right: 90%;
  } */
  .table-container {
    width: 100%;
    overflow-x: auto;
    background-color: #ffffff;
    margin-top: -1% !important;
  
    /* border-radius: 8px; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .table {
    margin-left: 20px;
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-td {
    /* text-align: left; */
    padding: 6px;
    border: none;
    word-wrap: break-word;
    white-space: pre;
  }
 
  
  .table-long-head {
    border-right: 1px solid white !important;
    /* text-align: center !important; */
    padding: 12px;
  
    border: none;
    word-wrap: break-word;
  }
  .staffId-search {
    width: 100px;
  }
  .filter-input {
    width:150px;
    /* justify-content: center; */
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 10px;
    text-align: center;
  }
  .filter-input-searchid {
    width: 150px;
    max-width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    white-space: pre;
  }
  .filter-select {
    width: 150px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 10px;
  }
  .photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .uploaded-btn {
    background-color: #00aaff;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .uploaded-btn:disabled {
    background-color: #8ed0f1;
  }
  .delete-btn {
    border: none;
               
    cursor: pointer;
    background-repeat: no-repeat;
    margin-left: 20px;
  }
  @media only screen and (max-width:750px) {
    .row-btn2 button {
   margin-top: 10px;
    }
  }
/* 
  .margin-right
{
margin-left:-70px !important;} */


.setting-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.setting-popup-container {
  background: white;
  width:900px;
  padding: 10px;
  border-radius: 8px;
  position: relative;
}

.setting-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.setting-popup-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.setting-checkbox-row {
  display: flex;
  justify-content: flex-start;
}

.setting-checkbox-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap:5px;
  font-size: 19px;
  text-align: justify;
}

.setting-label {
  font-size: 14px;
}

.setting-submit-btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  align-self: flex-end;
}
.bg-white{
  background-color: white;
}
.text{
  text-align: center;
}
.img-up-btn{
  border: 1px solid black;
}
.uploaded {
  background-color: #8ed0f1;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  display: inline-block;
  width: 100px;
}

.pending {
  background-color: #8ed0f1;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  display: inline-block;
  width: 100px;
}
/* //checkbox tick style */
/* input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid blue;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background: blue;
  position: relative;
}

input[type="checkbox"]:checked::after {
  content: '✔';
  color: white;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: -2px;
  left: 4px;
} */

.table-filter-down{
  margin-top: 5px !important;

}
.table-input-search {
   
  margin-bottom: 12px;
  align-items: center;
}
 
.search-adjust{
  text-align: center !important;
}
.td-input-spc{
  padding: 5px;
     
}