.dashborder {
  border: 2px solid #d7d7d7;
  border-bottom: 3px solid #d7d7d7;
   
  
  margin-left: 10% !important;
  border-radius: 10px;
 
}
.box-txt{
  font-size: 13px;
}
 .checkbox-down 
 {
  padding-top:-20px !important;
 }

 
.customer-row {
  margin-left: 0px;
}

.btnadd {
 
}

.button-bottom-custmer{
  margin-bottom: -1.5% !important;

}

.tablehead {
  /* padding: 10px; */
  background-color: #2e5caf;
  color: white;
  font-size: small;
}

.scrolltable {
  overflow-x: scroll !important;
  /* margin-left: -3.5% !important; */
}

/* .table th {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;

  white-space: pre;
  border: 2px solid white;
} */

.searchin {
  width: 150px;
  height: 35px;
  padding-left: 10px;
}
.background-change
{
  background-color: white;
}
.options {
  width: 150px;
  height: 35px;
  background-color: #e8ecef;
  margin-top: 10px;
  border-radius: 5px;
  padding-left: 10px;
  border: 2px solid #9e9b9b;
}
.table-search {
  margin-top: 10px;
}

 
.export {
  margin-right: -7%;
}

.deletebtn {
  border: none;
  background-color:none;
  margin-left: 25px;
  padding-top: px;

}

.setting-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.setting-popup-container {
  background: white;
  width: 900px;
  /* padding: 10px; */
  border-radius: 8px;
  position: relative;
}

.checbox-popup input[type=checkbox]
{
  padding: 10px !important;
}

.setting-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.setting-popup-content {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
}

.setting-checkbox-row {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;

}

.setting-checkbox-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* gap:5px; */
  font-size: 14px;
  text-align: justify;
}

.setting-label {
  font-size: 14px;
}

.setting-submit-btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  align-self: flex-end;
}


/* Account Status */
.status-active {
  background-color: #b0e9bd;
  color: #2c693a;


  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;      /* So width is respected for inline content */
  width: 100px;                /* or min-width: 80px for some flexibility */
  text-align: center;
  margin-left: 15px;
  font-weight: bolder;
}

.status-inactive {
  background-color: #a7a7a7;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;      /* So width is respected for inline content */
  width: 100px;                /* or min-width: 80px for some flexibility */
  text-align: center;
  margin-left: 15px;
  font-weight: bolder;

}

.status-suspended {
  background-color: #cfa87b;
  color: #663815;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;      /* So width is respected for inline content */
  width: 100px;                /* or min-width: 80px for some flexibility */
  text-align: center;
  margin-left: 15px;
  font-weight: bolder;

}

.status-disable {
  background-color: #f07c7c;
  color: #a73535;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;      /* So width is respected for inline content */
  width: 100px;                /* or min-width: 80px for some flexibility */
  text-align: center;
  margin-left: 15px;
  font-weight: bolder;

}

/* Device Status */
.device-online {
  background-color: #fbdc6e; /* bright yellow */
  color:rgb(94, 94, 14);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;      /* So width is respected for inline content */
  width: 100px;                /* or min-width: 80px for some flexibility */
  text-align: center;
  margin-left: 15px;
  font-weight: bolder;

}

.device-offline {
  background-color: #7dabff; /* bootstrap blue */
  color: #2e5caf;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;      /* So width is respected for inline content */
  width: 100px;                /* or min-width: 80px for some flexibility */
  text-align: center;
  margin-left: 15px;
  font-weight: bolder;

}


.customer-scroll-stop{
  overflow-x: hidden !important;
}



.search-input-box-text-center{
  text-align: center !important;
}










.black-btn1
{
  margin-top: 5px;
}

label.label-setting-popup {

  font-size: 15px !important;
  margin-top:10px;
margin-bottom: 10px;
margin-left: 10px;
}

/* input [type="checkbox"].checbox-popup{
  transform: scale(1.5);
  margin-right: 5px;
} */

.checkbox-customer{
  margin-left: -10px !important;
}