.border-container {
  border: 2px solid grey;
  padding: 20px;
  text-align: center;
  height: auto;
  width: 95%;
}
.border-container1 {
  margin-top: 30px;
  width: 90px;
}

.border-container-stock {
  border: 2px solid grey;
  padding: 20px;
  text-align: center;
  height: auto;
  width: 95%;
  /* margin-left: 30px; */
}
.plus-blue-btn {
  width: 50px !important;
}
.stock-head {
  margin-left: -60px !important;
  width: 150px !important;
}
.addstock-label {
  font-size: 17px;
}

.add-material {
  width: 10rem !important;
  margin-left: -0.5rem;
  margin-top: 1rem !important;
}

.border-container-plus1 {
  border: 1px solid grey;
  border-radius: 0.5rem;
  background-color: #eef7ff;
  padding: 20px;
  text-align: center;
  height: auto;
  /* width:100% !important; */
  /* margin-left: 1px !important; */
}

.stock-submit-btn {
  margin-right: 6% !important;
}
