.location-first-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px; /* Fixed gap issue */
  margin-top: 40px;
}

.location-first-row-left {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Changed to flex-start for better alignment */
  gap: 30px; /* Adjusted spacing */
}

.location-first-row-right {
  display: flex;
  gap: 10px;
}

/* Button Styling */
.location-btn {
  background-color: #eef7ff;
  border: none;
  gap: 25px;
  padding: 10px 15px;
}

/* Select Dropdown */
.form-select {
  min-width: 170px;
  height: 60px;
  padding: 10px 20px;
  margin-right: 25px;
  margin-left: 10px;
  background-color: #eef7ff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

/* Calendar Dropdown */
.location-month-calendar {
  min-width: 190px;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0 !important;
  background-color: #eef7ff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.locationRow-btn1 {
  display: flex;
  margin-left: -10px;
}

.row-btn2 {
  display: flex;
}
/* Responsive Design */
@media (max-width: 768px) {
  .location-first-row {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }

  .location-first-row-left {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }
  .form-select {
    width: auto;
    height: 60px;
    padding: 10px 20px;
    background-color: #eef7ff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  .location-first-row-right {
    justify-content: flex-start;
    gap: 15px;
  }

  .location-btn {
    width: 100%; /* Full width for better mobile experience */
    text-align: center;
  }
}
