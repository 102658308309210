
.taskRow1
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-right: 30px;
    margin-bottom: 0;
    overflow: hidden; 
  position: relative; 
  padding-left: 20px;
  padding-right: 30px;
}


.taskBtn
{
    outline: none;
    background-color:#667799;
    color: white;
    color:#681E0D;
    padding: 10px 30px 10px 30px;
    border: none;
    border-radius: 5px;
    align-items: baseline;
    margin-right: 20px;
}
.mapImage
{
    width: 50px;
    height: 50px;
   
    bottom: 0;
    padding-bottom: 0;
    margin: 0;
    
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center; 
    vertical-align: bottom;
    border: 1px solid black;
    border-radius: 5px;
}
.rightBox{
    display: flex;
flex-direction: row;
justify-content: flex-end;    
align-items: baseline;
padding-bottom: 10px;
align-self: baseline;

}
.taskRight
{
    text-align: right;
    justify-content: flex-end;
    
}
.taskColumn
{
    padding-left: 5px;
    min-height: 350px;
    
    margin-left:25px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }
    
.taskRow2
{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: flex-start;
    /* padding-bottom: 10px; */
    gap: 5px;
    padding-top: 20;
    margin-top: 30;

  }

.status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    
  }
  .status-container1 {
    display: flex;
    justify-content:flex-end;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    
  }
  
  .status-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: bold;
    color: #1c1035; 
  }
  .status-item1 {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: bold;
    color: #1c1035; 
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  
  .red {
    background: red;
  }
  
  .purplebox {
    background: #9747FF;
  }
  .orangebox, .openbox
  {
    background: #F3B44E;
  }
  .greenbox
  {
    background: #578C63;
  }
  .progressbox
  {
    background: #5f5f5f;
  }
  .bluebox
  {
    background: #5AC3DD;
  }
  .hightext
  {
    background: #FFBDBD !important;
  }
  .lowtext
  {
    background: #7DABFF;
    color:#092F75;
  }
  .status-count {
    background: #E0E0E0;
    padding: 3px 8px;
    border-radius: 50%;
    font-size: 12px;
    color: #625F6D;
  }
  
  .underlineTask_SB {
    width: 91%;
    height: 3px;
    background:#E85A5A;
    margin-top: 5px;
    margin: auto;
    margin-bottom: 10px;
  }


  .underlineTask {
    width: 91%;
    height: 3px;
    background:#E85A5A;
    margin-top: 5px;
    margin: auto;
    margin-bottom: 30px;
  }
  .underlineTask_2 {
    width: 91%;
    height: 3px;
    background:#FFA500;
    margin-top: 5px;
    margin: auto;
    margin-bottom: 30px;
  }
  .underlineTask_3 {
    width: 91%;
    height: 3px;
    background:#68B266;
    margin-top: 5px;
    margin: auto;
    margin-bottom: 30px;
  }
  .offcanvas-end {
    transform: translateX(100%) !important; /* Right Side Ku Move Pannanum */
  }
  
  .offcanvas.show {
    transform: translateX(0%) !important; /* Show Pannum Bothu Right Side La Varum */
  }

  .offcanvas.show {
    visibility: visible !important;
    transform: translateX(0) !important;
    right: 0 !important;
    height: 100vh !important;
  }

  .offcanvas.offcanvas-end {
    position: fixed !important;
    right: 40px !important;
    top: 160px !important;
    width: 400px !important;
    height: 600px !important;
    z-index: 1050 !important;
    background-color: white;
    overflow-y: scroll;
  }
  
  .taskBtn:active {
    background-color: #7C8DB5 !important;
    transform: scale(0.98); /* Press Effect Ku Vera Level Look */
  }
  .status-container-row
{
  margin-top: 20px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.task-enquiry {
  position: absolute;
  top: -12px;
  left: 10px;
  background-color: #9333ea !important;

  padding: 5px 10px;
  border-radius: 10px;
  width: 30%;
  min-height: 9%;
  text-align: left;
 
  }
  .task-recovery {
  position: absolute;
  top: -12px;
  left: 10px;
  background-color: #F3B44E !important;

  padding: 5px 10px;
  border-radius: 10px;
  width: 30%;
  min-height: 9%;
  text-align: left;
 
  }
  .task-enquiry {
    position: absolute;
    top: -12px;
    left: 10px;
    background-color: #9333ea !important;
  
    padding: 5px 10px;
    border-radius: 10px;
    width: 30%;
    min-height: 9%;
    text-align: left;
   
    }
  .progress-main {
    background-color: #5F5F5F !important;
    border-radius: 14px;
    color: white;
    position: absolute;
    right:22px;
    top:-12px;
    width: 28%;
    min-height: 9%;
    text-align: center;
    font-size: 10px;
  }
  

  .progress-body
  {
    position: absolute;
    font-size: 12px;
    text-align: center;
    margin: auto;
    padding-top: 0;
    top:0;
    left: 25%;
    
  }
  .task-description {
    font-size: 14px;
    color: #4a4a4a;
    text-align: justify; 
    line-height: 1.6; 
    font-weight: 500;
    margin-bottom: 55px;
  }
  .customer-scroll-stop{
    overflow-x: hidden !important;
  
  }
  
  @media screen and (max-width: 520px) {
    .taskRight
{
    text-align: left;
}
  }


  .taskColor1
  {
    background-color: #f2b50d;
  }
  
  .task-popup-container {
    background: white;
    height: 75px;
    border-radius: 7px;
    position: absolute;
    right:20px;
    top:35px;
    padding: 0;
    margin: 0;
  width: 150px !important;
  }
  .task-close-btn {
    position: absolute;
    top: 0px;
    right: 10px;
    background: none;
    border: none;
    font-size: 22px;
   
    cursor: pointer;
  }
  .task-popup-content {
    display: flex;
    flex-direction: column;
  /* border:2px solid red; */
    width: 85%;
    justify-content: flex-end;
    margin: auto;
    /* margin-bottom: 10px; */
  }
  .popicon
  {
    display: inline;
    border:1px solid red;
  }
  .task-card-box {
    width: 350px;
    padding: 10px;  
    position: relative;
    border: none;
    border-radius: 55px;
    overflow: visible; 
    margin-bottom: 25px;
    cursor: pointer;
    background-color: #D8D8D8;
    padding-bottom: 0px;
    padding-right: 0px;
  text-align: center;
   
  }
  .task-card-item
  {
    width: 90%;
    border-radius: 25px;
    padding: 10px;
  }
  .assignedBy
  {
    font-size: 12px;
    color: rgb(141, 137, 137);
    text-align: left;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .approvedBy
{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  line-height: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;;
}
.avatars {
  display: flex;
 
}
.avatars1 {
  display: flex;
  /* gap: 4px; */
}

.avatars img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid white;
}

.avatar-img1 {
  width: 25px;
  height: 25px;
  border: 2px solid white;
  padding-bottom: 0;
  margin-bottom: 0;
}

.avatar-img {
  width: 35px;
  height: 35px;
  border: 2px solid white;
}

.dots {
  cursor: pointer;
  font-size: 20px;
}
.popup-box {
  background: #f1f1f1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  position: absolute;
  right: 0;
  z-index: 1;
}
