* {
  padding: 0;
  margin: 0;
}
.att-generate-btn{
  height: 40px;

  background-color: #2e5caf;
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
}
.att-generate{
  margin-right: 40% !important;
}
.card-background {
  border: 1px solid rgb(193, 189, 189);
  box-shadow: 2px;
  border-radius: 3px;
  background-color: white;
  height: auto;
}
.card-border {
  border: 2px solid #d7d7d7;
  height: 90px;
  border-radius: 10px;
 
}
.attendance-exp-btn{
    margin-left: 80%;
}
.align-box{
    width: 100% !important;
    margin-left: 0% !important;
}
.work-card{
    padding: 16px  !important;
}
@media(max-width:1200px){
    .attendance-exp-btn{
        margin-left: 70%;
    }
    .work-card{
        padding: 8px  ;
    }
}

.attendance-table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .attendance-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
     
  }
  
  .attendance-th, .attendance-td {
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  
  
  .row-label {
    background-color: #f8c6c6;
    font-weight: bold;
  }
  
  