.sidebar-container {
  display: flex;
  height: 100vh;
  overflow-y: hidden;
}

.sidebar {
  width: 231px;
  background-color: #363740; /* Dark gray */
  color: white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  /* height:700px !important; */
}

.logo {
  padding: 5px;
  font-size: 22px;
  font-weight: bold;
  margin-left: 15%;
  padding-left: 15px;
}

.menu {
  list-style: none;
  padding: 0;
  margin-left: 15px;
  flex: 1;
  margin-top: -5px;
}

.menu-item1 {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;

  border-radius: 6px;
  width: 100px !important;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
 
  transition: background-color 0.3s;
  background-color: transparent;
  border-radius: 6px;
}

 
.menu-item.active {
  background-color: #2d6ed6; /* Blue color */
  color: white;
   
 
}

/* .submenu:hover {
  padding-left: -10px !important;
} */

.icon {
  font-size: 16px;
  margin-left: 1vw;
}
.items {
  font-size: 16px;
  margin-left: 1vw;
  background-color: transparent !important;
}
.icon-stock {
  font-size: 16px;
  margin-left: 10px;
}
.items-stock {
  font-size: 16px;
  background-color: transparent;
}
.icon-admin {
  font-size: 16px;
  margin-left: 10px;
}
.items-admin {
  font-size: 16px;
  margin: 5px;
  background-color: transparent;
}
.submenu {
  margin-left: 15%;
 
}
.main-content {
  flex: 1;
  background-color: #f9fafb; /* Light gray for contrast */
  padding: 20px;
}

.arrow-btn {
  border: none;
  color: white;
  background-color: #363740;
}
/* .arrow-btn:hover {
  background-color: #2d6ed6;
} */
