* {
  padding: 0;
  margin: 0;
}
.satffanalysis-selectcheckbox {
  background-color: #eef7ff;
}
.staffanalysis-select {
  height: 40px;
  text-align: center;
  background-color: #eef7ff;
  outline: none;
  border: 1px solid rgb(180, 178, 178);
  border-radius: 3px;
  cursor: pointer;
}
.staff-generate-btn {
  height: 40px;

  background-color: #2e5caf;
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
}
.staff-generate {
  float: right;
}
.sa-box{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.sa-linechart-box{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
}
.sa-piechart-box{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.sa-purple {
  background-color: #A259FF;
}

.sa-pink {
  background-color: #FF7675;
}
.sa-darkblue{
  background-color: #195C9A;
}
.sa-skyblue{
  background-color: #66E6FF;
}
.sa-brown{
  background-color: #B87333;
}