.task-card {
  width: 370px;
  padding: 20px;  
  position: relative;
  border: none;
  border-radius: 15px;
  overflow: visible; 
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #dddbdb;
  padding-bottom: 15px;
  padding-right: 35px;
  
}

.task-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: absolute;
  top:0;
  font-size: 10px;
  border:3px solid blue;
}

.dots {
  font-size: 20px;
  cursor: pointer;
  text-align: right;
  font-weight: 900;
  line-height: 0px;
  margin-bottom: 15px;
}

.task-description {
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  text-align: justify;
}

.task-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.assigned-by {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priority {
  background: #a5c6f8; /* Blue Color */
  padding: 3px 10px;
  border-radius: 10px;
  font-size: 12px;
}

.avatars {
  display: flex;
  /* gap: 4px; */
}
.avatars1 {
  display: flex;
  /* gap: 4px; */
}

.avatars img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid white;
}

.avatar-img1 {
  width: 25px;
  height: 25px;
  border: 2px solid white;
  padding-bottom: 0;
  margin-bottom: 0;
}

.avatar-img {
  width: 35px;
  height: 35px;
  border: 2px solid white;
}

.enquiry {
  background-color: #9333ea !important;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  border:none;
  position: absolute;
  left: 5px;
  top:-6px;
  
}
.enquiry {
position: absolute;
top: -9px;
left: 15px;
background-color: #9333ea !important;
color: white;
padding: 5px 10px;
border-radius: 10px;
width: 30%;
min-height: 9%;
text-align: left;
}
.progress {
  background-color: #5F5F5F !important;
  border-radius: 14px;
  color: white;
  position: absolute;
  right:15px;
  top:-15px;
     width: 30%;
     min-height: 9%;
     text-align: center;
}
.open {
  background-color: #f2b50d !important;
  border-radius: 14px;
  color: white;
  position: absolute;
  right:15px;
  top:-8px;
     width: 30%;
     min-height: 7%;
     text-align: center;
}
.progress {
  background-color: #5F5F5F !important;
  border-radius: 14px;
  color: white;
  position: absolute;
  right:15px;
  top:-8px;
     width: 30%;
     min-height: 9%;
     text-align: center;
}
.recovery {
  background-color:#f2b50d !important;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  border:none;
  position: absolute;
  left: 5px;
  top:-6px;
}
.install {
  background-color:#578C63 !important;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  border:none;
  position: absolute;
  left: 5px;
  top:-6px;
}
.progress-body
{
  position: absolute;
  font-size: 12px;
  text-align: center;
  margin: auto;
  padding-top: 0;
  top:0;
  left: 25%;
  
}
.open-body
{
  position: absolute;
  font-size: 12px;
  text-align: center;
  margin: auto;
  padding-top: 0;
  top:0;
  left: 25%;
  
}
.task-description1 {
  font-size: 14px;
  color: #4a4a4a;
  text-align: justify; 
  line-height: 1.6; 
  font-weight: 500;
}

.high-text
{
color:#B40707 !important;

}
