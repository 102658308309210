.admin-container{
    width:100% !important;
    float:left;

}

.table-container-accesstable{
    width:100%;
    float:right;

}
