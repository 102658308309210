.dashborder {
    border: 2px solid #d7d7d7;
    border-bottom: 3px solid #d7d7d7;
     
    /* vertical-align: top; */
    margin-left: 10% !important;
    border-radius: 10px;
    
   
  }
  .yellow-card1 {
    background-color: #fbdc6e;
    color: rgb(94, 94, 14);
    height: 50px;
    width: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    margin-bottom: 0px;
    font-weight:bolder;
    vertical-align: middle;
  }
  .grey-card1 {
    background-color: #a7a7a7;
    color: white;
    height: 50px;
    width: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    margin-bottom: 0px;
    font-weight:bolder;
    vertical-align: middle;
  }
  .orange-card1 {
    background-color: #ffa770;
    color: #7b6512;
    height: 50px;
    width: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    margin-bottom: 0px;
    font-weight:bolder;
    vertical-align: middle;
  }
  .pink-card1 {
    background-color: #ffa5ee;
    color: #a73535;
    height: 50px;
    width: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
vertical-align: middle;
    font-weight:bolder;
  }
  .red-card1 {
    background-color: #f07c7c;
    color: #a73535;
    height: 50px;
    width: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    vertical-align: middle;
    margin-bottom: 5px;
    font-weight:bolder;
  }
  
  .purple-card1 {
    background-color: #ccb7ff;
    color: #5b4591;
    height: 50px;
    width: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    font-weight:bolder;
    vertical-align: middle;
  }
  .blue-card1 {
    background-color: #7dabff;
    color: #2e5caf;
    height: 50px;
    width: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 5px;
    font-weight:bolder;
  }
  .green-card1 {
    background-color: #b0e9bd;
    color: #2c693a;
    height: 50px;
    width: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 5px;
    font-weight:bolder;
  }
  
  .box-txt{
    font-size: 13px;
    /* border: 2px solid red; */
    vertical-align:bottom;
    margin-top: 15px;
  }
   .checkbox-down 
   {
    padding-top:-20px !important;
   }
  .dash-top{
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    padding: 0;
    margin: 0;
    align-self: center;
  }
  .yellow-card-enquiry {
    background-color: #fbdc6e;
    color: rgb(94, 94, 14);
    height: 40px;
    width: auto;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    /* padding-top: 8px; */
    margin-bottom: 5px;
    font-weight:bolder;
  }
   
  .customer-row {
    margin-left: 0px;
  }
  
  .btnadd {
   
  }
  
  .button-bottom-custmer{
    margin-bottom: -1.5% !important;
  
  }
  
  .btn-Ccolumn2
  {
    margin-top: 15px;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .tablehead {
    /* padding: 10px; */
    background-color: #2e5caf;
    color: white;
    font-size: small;
  }
  
  .scrolltable {
    overflow-x: scroll !important;
    /* margin-left: -3.5% !important; */
  }
  
  .table {
    margin-left: 0px;
  } 
  
  .searchin {
    width: 150px;
    height: 35px;
    padding-left: 10px;
  }
  .background-change
  {
    background-color: white;
  }
  .options {
    width: 150px;
    height: 35px;
    background-color: #e8ecef;
    margin-top: 10px;
    border-radius: 5px;
    padding-left: 10px;
    border: 2px solid #9e9b9b;
  }
  .table-search {
    margin-top: 10px;
  }
  
   
  .export {
    margin-right: -7%;
  }
  
  .deletebtn {
    border: none;
    background-color:none;
    margin-left: 25px;
    padding-top: px;
  
  }
  
  .setting-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .setting-popup-container {
    background: white;
    width: 900px;
    /* padding: 10px; */
    border-radius: 8px;
    position: relative;
  }
  
  .setting-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .setting-popup-content {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
  }
  
  .setting-checkbox-row {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .setting-checkbox-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* gap:5px; */
    font-size: 19px;
    text-align: justify;
  }
  
  .setting-label {
    font-size: 14px;
  }
  
  .setting-submit-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    align-self: flex-end;
  }
  
  
  /* Account Status */
  .status-active {
    background-color: #b0e9bd;
    color: #2c693a;
  
  
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-block;      /* So width is respected for inline content */
    width: 100px;                /* or min-width: 80px for some flexibility */
    text-align: center;
    margin-left: 15px;
    font-weight: bolder;
  }
  
  .status-inactive {
    background-color: #a7a7a7;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-block;      /* So width is respected for inline content */
    width: 100px;                /* or min-width: 80px for some flexibility */
    text-align: center;
    margin-left: 15px;
    font-weight: bolder;
  
  }
  
  .status-suspended {
    background-color: #cfa87b;
    color: #663815;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-block;      /* So width is respected for inline content */
    width: 100px;                /* or min-width: 80px for some flexibility */
    text-align: center;
    margin-left: 15px;
    font-weight: bolder;
  
  }
  
  .status-disable {
    background-color: #f07c7c;
    color: #a73535;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-block;      /* So width is respected for inline content */
    width: 100px;                /* or min-width: 80px for some flexibility */
    text-align: center;
    margin-left: 15px;
    font-weight: bolder;
  
  }
  
  /* Device Status */
  .device-online {
    background-color: #fbdc6e; /* bright yellow */
    color:rgb(94, 94, 14);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-block;      /* So width is respected for inline content */
    width: 100px;                /* or min-width: 80px for some flexibility */
    text-align: center;
    margin-left: 15px;
    font-weight: bolder;
  
  }
  
  .device-offline {
    background-color: #7dabff; /* bootstrap blue */
    color: #2e5caf;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-block;      /* So width is respected for inline content */
    width: 100px;                /* or min-width: 80px for some flexibility */
    text-align: center;
    margin-left: 15px;
    font-weight: bolder;
  
  }
  
  
  .customer-scroll-stop{
    overflow-x: hidden !important;
  
  }

  .black-btn1
  {
    margin-top: 5px;
  }
  /* Responsive */
  @media screen and (max-width: 520px) {
    .btn-Ccolumn2
  {
    margin-top: 15px;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .purple-card1 {
    
    padding-top: 0px;
    /* vertical-align:text-top; */
  }
  p.box-txt
  {
    line-height: 14px;
    padding-top: 0px;
  }
}