/* 1.1	Theme Reset Style */
html {
    font-size: $base-font-size + px;
    scroll-behavior: smooth;
}



body {
    font-family: $base-font;
    background-color: $body-color;
    font-size: 15px;
    overflow-x: hidden;


    @include media-query(767px) {
        font-size: 14px;
    }
}

p {
    font-size: 16px;
     

    @media (max-width:399px) {
        font-size: 15px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
    font-family: $heading-font;
    font-weight: 600;
}

ul {
    padding-left: 0;
    margin: 0;
}

a {
    text-decoration: none;
    @include transition-time(0.2s);
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}


.sr-only {
    display: none !important;
    opacity: 0;
    visibility: hidden;
}

/* 1.2 Global Elements */
.page-wrapper {
    position: relative;
    overflow: hidden;
}

.wow {
    visibility: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    padding: 130px 0;

    @include media-query(991px) {
        padding: 90px 0;
    }

    @include media-query(767px) {
        padding: 80px 0;
    }
}





/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}









/*** contact form error handling ***/
.contact-validation-active {
    .error-handling-messages {
        width: 100% !important;
        margin-top: 15px !important;
    }

    label.error {
        color: red;
        font-weight: normal;
        margin: 5px 0 0 0;
        text-align: left;
        display: block;
    }

    #c-loader,
    #loader {
        display: none;
        margin-top: 10px;

        i {
            font-size: 30px;
            color: $theme-primary-color;
            display: inline-block;
            -webkit-animation: rotating linear 2s infinite;
            animation: rotating linear 2s infinite;
        }
    }

    #success,
    #c-success,
    #c-error,
    #error {
        width: 100%;
        color: $white;
        padding: 5px 10px;
        font-size: 16px;
        text-align: center;
        display: none;

        @include media-query(767px) {
            font-size: 15px;
        }
    }

    #c-success,
    #success {
        background-color: lighten(green, 5%);
        border-left: 5px solid green;
        margin-bottom: 5px;
    }

    #c-error,
    #error {
        background-color: lighten(red, 5%);
        border-left: 5px solid red;
    }
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 5s alternate infinite;
    animation: rotating 5s alternate infinite;
}


/*** back to top **/
.back-to-top {
    background-color: transparentize($theme-primary-color, 0.3);
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 2px solid $theme-primary-color;
    border-radius: 45px;
    text-align: center;
    display: none;
    position: fixed;
    z-index: 999;
    right: 15px;
    bottom: 15px;
    @include transition-time(0.2s);

    @include media-query(991px) {
        width: 35px;
        height: 35px;
        line-height: 35px;
    }

    &:hover {
        background-color: $theme-primary-color;
    }
}

.back-to-top i {
    font-size: 18px;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    @include center-by-translating();
}


/** for popup image ***/
.mfp-wrap {
    background-color: transparentize($black, 0.1);
    z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999 !important;
}

.fancybox-wrap {
    z-index: 99999 !important;
}


html.lenis-scrolling {
    height: auto;
}

.lenis.lenis-scrolling {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

.cursor-ball {
    position: fixed;
    width: 10px;
    height: 10px;
    background: $small-black-s2;
    border-radius: 50%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: 0.2s;
    z-index: 1111;

    &.inner-ball {
        background: none;
        border: 1.1px solid $small-black-s2;
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 1200px) {
    .cursor {
        display: none;
    }
}

// dark-mode

.quickview-dialog{
    z-index: 99999!important;
   
    ::-webkit-scrollbar {
        width: 3px;
      }
       
      ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
      }
   
      .MuiButtonBase-root{
          z-index: 999;
      }
   }
   .MuiDialog-paperScrollPaper {
       max-width: 860px!important;
       width: 860px!important;
       background-color: #fff!important;
   }
   
.modal-close-btn {
  text-align: right;
  margin-bottom: 20px;

  button {
    width: 40px;
    height: 40px;
    border: 0;
    background: $text-color;
    border-radius: 5px;
    margin-right: 15px;
    color: $white;
  }
}   

.errorMessage {
    color: red;
    font-size: 15px;
    margin-top: 10px;
}


.switch-btn {
    position: fixed;
    right: 0px;
    top: 115px;
    z-index: 11;
    width: 50px;
    height: 50px;
    line-height: 58px;
    background: #e1e3e5;
    border: 0;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    i {
        color: $heading-color;
        font-size: 25px;
    }


    @media(max-width:575px) {
        width: 40px;
        height: 40px;
        line-height: 50px;
        top: 115px;
    }

}
