* {
  padding: 0;
  margin: 0;
}
.cr-export {
  float: right;
}
.cr-generate-btn {
  height: 40px;

  background-color: #2e5caf;
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
}
.cr-generate {
   
}
.complaints-report-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.connections-report-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.dues-report-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.leads-report-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
