* {
  margin: 0;
  padding: 0;
}
.lesser-icon1 {
  margin-left: 199px;
  cursor: pointer;
  padding: 2px 0px;
  background-color: #257abf;
  color: white;
  font-size: 20px;
  border-radius: 2px;
  /* margin-bottom: 20px; */
}
.lesser-icon2 {
  margin-left: 188px;
  cursor: pointer;
  padding: 2px 0px;
  background-color: #257abf;
  color: white;
  font-size: 20px;
  border-radius: 2px;
  /* margin-bottom: 20px; */
 
}
 
.head{
  margin-left: 3vw !important;
  
}
.table-head{
  padding:13px !important;
}


.setting-td{
 
  padding: 10px!important;
 
}
 

.table-border
{
  margin-top: 60px !important;
  margin-left: 370px !important;
}
 
@media only screen and (max-width: 800px) {
  .table-border{
      margin-left: 20% !important;
      margin-top: 20% !important;
      
  }
}

.edit-head
{
  background-color:#257abf ;
  color: white;
  font-size: 18px !important;
  
}