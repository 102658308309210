/* Layout for App */
.app-container {
    display: flex;
    height: 100vh; /* Full screen height */
    overflow: hidden;
}

header {
    flex: 0 0 230px; /* Set header width */
    background-color: #f4f4f4; /* Adjust as needed */
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}
@media(max-width:991px){
    header{
        flex: 0 0 20px; 
    }
}
.content-container {
 
    flex: 1;  
 
    overflow-x: hidden;
 
 
    background-color: #fff; /* Adjust as needed */
}
 