.table-admin {
  background-color: white;
  border-collapse: collapse;
  width: 20%;
  border-bottom: 2px solid black;
  /* Adds bottom border */
}


.fullcomponent
{
      /* position: absolute; */
      right:3%;
      top: 22%;
      max-width:100%;
      /* width:500px; */
      margin-top:-500px !important;

      
}

@media only screen and (max-width: 580px) {
  .fullcomponent{
    margin-top:250px !important;
  }
}

.checkbox-label label{
  padding-top: 8px !important;
  padding-left: 20px;
}



.arrow-button {
  position: relative;

  margin: 0;
 
  padding: 5px;
 
  border: none;
}


.accesstable1 input[type="checkbox"] {
  
  appearance: none;
  /* Hide default checkbox */
  width: 8px;
  height: 8px !important;
  border: 2px solid #333;
  cursor: pointer;
  transform: scale(0.6);
  /* Adjust size (0.7 means 70% of original size) */
  margin: 7px;
  
  padding: 13px;
}



.accesstable input[type="checkbox"] {
  
  appearance: none;
  width: 8px;
  height: 8px !important;
  border: 2px solid #333;
  cursor: pointer;
  transform: scale(0.6);

  margin: 5px; 
   margin-top: -5px !important;
  padding: 13px;
}


.accesstable1 input[type="checkbox"] {
  
  appearance: none;
  width: 8px;
  height: 8px !important;
  border: 2px solid #333;
  cursor: pointer;
  transform: scale(0.6);
  margin: 5px; 

  padding: 13px;
}


.all-accesstable label{
  margin-top: -5px !important;
}


@media only screen and (max-width: 767px) {
  .all-accesstable{
    margin-top: 10px;
    
  }
}
@media only screen and (max-width: 646px) {
  .all-accesstable{
    margin-top: 10px !important;
    
  }
}

.checkboxadmin
{
  margin-left: -25px !important;

}


 .accesstable input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #007bff;

} 



.tableborder-admin {
  border: 2px solid #A7A7A7;
  padding: 20px;
  overflow-y: scroll;
  max-height: 600px;
  overflow-x: hidden;
  margin-top: -20px;
}

.table-admin tr {
  border-bottom: 2px solid #d7d4d4;
}

.table-admin th {
  padding: 14px;
  padding-left: 18px;
}

.table-admin td {
  padding-left: 15px;
  font-size: smaller;

}

.table-admin table {
  border: 2px solid;
}

.taskrow {
  background-color: #EEF7FF;
  border: 2px solid #A7A7A7;
  border-radius: 5px;
  max-width: 300px;
}


.button-border {
  border: none !important;

}

.admin-submit {
  margin-right: 0px;
  margin-left: 89% !important;
  
  margin-bottom: 30px !important;

}
.roles-top1{
  text-align: start;
  margin-bottom: -15% !important;
  color:white;
  padding: 10px;
}
.border-header-admin{
  width:250px;
  background-color:#565656 ;
  color:white;
  border-radius: 5px;

}

.accesstable-font-size{
  font-size: 16px !important;

}

.accesstable-th-fontsize{
  font-size: 17px !important;

}