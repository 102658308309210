.menu-open {
    position: absolute;
    left: 10px; /* Moves it to the left side */
    top: -29px; /* Adjusts vertical positioning */
    cursor: pointer;
    z-index: 1000; /* Ensures it's above other elements */
}

@media (min-width: 992px) {
    .menu-open {
        left: auto; /* Resets for larger screens */
        right: 10px; /* Default position */
    }
}

.menu {
    list-style: none;
    padding: 0;
    margin-left: 15px;
    flex: 1;
    margin-top: -5px;

     
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    border-radius: 6px;
  }
  
  .menu-item:hover {
    background-color: #2d6ed6; /* Hover background */
  }
  
  .icon {
    font-size: 10px;
    margin-left: 1vw;
  }
  .items {
    font-size: 10px;
    margin-left: 1vw;
  }