.table-scroll{
    overflow-x: scroll !important;
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents text wrapping */
}
.stock-table {
    width: 100%;
    border-collapse: collapse;
    position:sticky !important;
   
}
/* //sticky table */
.sticky-table th, .sticky-table td {
  border: 1px solid #ccc; /* Table borders */
  padding: 8px; /* Padding for table cells */
  text-align: center;
}

.sticky-col {
  position: sticky; /* Makes the column sticky */
  left: 0; /* Stick to the left */
  background-color: #fff; /* Optional: Match table background */
  z-index: 1; /* Ensures the sticky columns stay above the rest */
  border-right: 1px solid #ccc; /* Optional: Add a visual divider */
}

.sticky-table thead .sticky-col {
  z-index: 2; /* Higher stacking for the header cells */
}
/* //---- */
.step-table{
    border-left: 1px solid wheat;
}
.table-td{
    text-align: left;
    padding: 6px;
    border: none;
    word-wrap: break-word;
  }
  .wrap{
    border-right:1px solid white;
    word-wrap: break-word;
    white-space: pre;
  }
  .sub-bg-color{
    background-color:#257abf !important;
    color: white;
 
  }
  .box-txt{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    font-size: 13px;
  }
  .stock-table-align tr{
   text-align: center !important;
     
  }
  .table-th-re{
      padding: 8px !important;
  }
  .stock-search-wrap{
    word-wrap: break-word !important;
    white-space: pre !important;
  }
  .stock-search-wrap::placeholder{
    font-size: 12px;
  }
  .date-popup{
    padding: 15px;
   border: none !important;
    position: absolute !important;
     top:144px;
     background-color: white;
     left: 4px;
     border: 1px solid #a7a7a7 !important;
     
    
  }
  .date-popup a{
    text-decoration: none !important;
    color: black;
    cursor: pointer;
  }
  .date-popup a:hover{
    color: black;
  }
  .stock-cross{
    margin-bottom: 10px;
    margin-left: 90px;
    font-size: 20px;
  }
  
 
  /* //stock setting  */
  
   .checkbox-label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    margin: 8px 0;
  }
  
  .staff-popup-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
  }
  
  .checkbox-group {
    margin-top: 16px;
  }
  
  .stock-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content:space-around;
    align-items: center;
    z-index: 1000;
  }
  
  
  .stock-popup-container {
    background: white;
      border-radius: 10px;
      padding: 20px;
      width: 700px;
      max-width: 90%;
      position: relative;
     
  }
  
  .stock-popup-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size:35px;
    cursor: pointer;
    font-weight: 10px;
  }
  
  .submit-btn {
    background-color: green;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      cursor: pointer;
      font-size: 14px;
      margin-left: 85%;
  }
  .cancle-date{
    width: 70px;
    height: 30px;
    background-color:rgb(164, 161, 161);
    padding: 5px;
    color: white;
    border-radius: 5px;
    border: none;
    text-align: center;
  }
  .blue-date{
    width: 70px;
    height: 30px;
    background-color: #326bff;
    padding: 5px;
    color: white;
    border-radius: 5px;
    border: none;
    text-align: center;

  }
  .custom-range{
    width: 100px;
    
    
  }
  .form-input-date {
    background-color:white;
    padding-left:10px !important;
    border: 2px solid #a7a7a7;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 15px;
    width:50%;
  }
  .custom-date-container{
    margin-left:150px;
    z-index: 1000;
  }
  /* Popup background */
.date-popup {
  position: absolute;
  top: 210px;
  left: 18px;
  background: white;
  border: 1px solid #ccc;
  padding: 12px;
  z-index: 10;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
  width: 220px;
}

.stock-cross {
  position: absolute;
  top: 6px;
  font-size: 20px !important;
  cursor: pointer;
  color: #333;
}

.date-popup a {
  display: block;
  color: #333;
  padding: 6px 8px;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}

.date-popup a:hover {
  background-color: #f2f2f2;
}

/* Custom range section */
.custo {
  position: absolute;
  top: 290px;
  left: 450px;
  z-index: 14;
  background: white;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
}

.custom-range {
  
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
}

.form-input-date {
  width: 120px;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
}

 /* {//datepopup} */


/* Sidebar */
.date-sidebar {
  border-right: 1px solid #eee;
  padding: 12px;
  background-color: #fff;
}

.date-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.date-sidebar li {
  padding: 8px 2px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 10px !important;
}

.date-sidebar li:hover {
  background-color: #f0f0f0;
}

.date-sidebar .custom-range {
  font-weight: bold;
  color: #007bff;
}

/* Calendar Panel */
.calendar-panel {
  padding: 12px;
  background-color: #fff;
}

/* Footer below calendar */
.calendar-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  gap: 10px;
}

.calendar-footer input {
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  width: 130px;
}

.calendar-footer button {
  padding: 6px 12px;
  font-size: 13px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.calendar-footer button:first-of-type {
  background-color: #eee;
}

.calendar-footer button:last-of-type {
  background-color: #007bff;
  color: white;
}










.popup-overlay {
  position: absolute;
  top: 58%;
  left: 0;
  width: 150px;
  /* height: 100%; */
  /* background-color: rgba(0, 0, 0, 0.4);  */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  margin-left:-100px  !important ;

  /* position: absolute;
  top: 60%; 
  left: 0;
  width: 200px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 10px 0; */
}

.date-picker-wrapper {
  margin-left: 15rem !important;
  background-color: white;
  /* padding: 20px; */
  padding-left:10px ;
  display: flex;
  gap: 20px;
  border-radius: 10px;
  max-width: 90%;
  z-index: 1001;
  border: 2px solid rgb(177, 174, 174);
}


/* .date-picker-wrapper {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: fit-content;
  font-family: sans-serif;
} */

.date-sidebar ul {
  list-style: none;
  padding: 0;
}

.stock-cross {
  cursor: pointer;
  float: right;
  font-size: 20px;
}

.calendar-footer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}


/* Ensure columns from the 5th onward remain scrollable */



.table>:not(:first-child) {
  border-top:none !important;
}


.table-scroll{
  overflow-x: scroll !important;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents text wrapping */
}
.stock-table {
  width: 100%;
  border-collapse: collapse;
  position:sticky !important;
 
}
 
.step-table{
  border-left: 1px solid wheat;
}
.table-td{
  text-align: left;
  padding: 6px;
  border: none;
  word-wrap: break-word;
}
.wrap{
  border-right:1px solid white;
  word-wrap: break-word;
  white-space: pre;
}
.sub-bg-color{
  background-color:#257abf !important;
  color: white;

}
.box-txt{
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  font-size: 13px;
}
.stock-table-align tr{
 text-align: center !important;
   
}
.table-th-re{
    padding: 8px !important;
}
.stock-search-wrap{
  word-wrap: break-word !important;
  white-space: pre !important;
}
.stock-search-wrap::placeholder{
  font-size: 12px;
}
.date-popup{
  padding: 15px;
 border: none !important;
  position: absolute !important;
   top:144px;
   background-color: white;
   left: 4px;
   border: 1px solid #a7a7a7 !important;
   
  
}
.date-popup a{
  text-decoration: none !important;
  color: black;
  cursor: pointer;
}
.date-popup a:hover{
  color: black;
}
.stock-cross{
  margin-bottom: 10px;
  margin-left: 90px;
  font-size: 20px;
}


/* //stock setting  */

 .checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  margin: 8px 0;
}

.staff-popup-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.checkbox-group {
  margin-top: 16px;
}

.stock-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.stock-popup-container {
  background: white;
    border-radius: 10px;
    padding: 20px;
    width: 700px;
    max-width: 90%;
    position: relative;
   
}

.stock-popup-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size:35px;
  cursor: pointer;
  font-weight: 10px;
}

.submit-btn {
  background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 85%;
}
.cancle-date{
  width: 70px;
  height: 30px;
  background-color:rgb(164, 161, 161);
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
  text-align: center;
}
.blue-date{
  width: 70px;
  height: 30px;
  background-color: #326bff;
  padding: 5px;
  color: white;
  border-radius: 5px;
  border: none;
  text-align: center;

}
.custom-range{
  width: 250px;
  
  
}
.form-input-date {
  background-color:white;
  padding-left:10px !important;
  border: 2px solid #a7a7a7;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
  width:50%;
}
.custom-date-container{
  margin-left:150px;
  z-index: 1000;
}
/* Popup background */
.date-popup {
position: absolute;
top: 210px;
left: 18px;
background: white;
border: 1px solid #ccc;
padding: 12px;
z-index: 10;
border-radius: 6px;
box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
width: 220px;
}

.stock-cross {
position: absolute;
top: 6px;
right: 8px;
font-size: 22px;
cursor: pointer;
color: #333;
}

.date-popup a {
display: block;
color: #333;
padding: 6px 8px;
text-decoration: none;
cursor: pointer;
font-size: 14px;
border-radius: 4px;
}

.date-popup a:hover {
background-color: #f2f2f2;
}

/* Custom range section */
.custo {
position: absolute;
top: 290px;
left: 450px;
z-index: 14;
background: white;
padding: 12px;
border-radius: 6px;
border: 1px solid #ccc;
box-shadow: 0px 4px 10px rgba(0,0,0,0.1);
}

.custom-range {

border: 1px solid #ccc;
border-radius: 6px;
padding: 10px;
}

.form-input-date {
width: 120px;
padding: 6px 10px;
border: 1px solid #ccc;
border-radius: 4px;
margin-top: 10px;
font-size: 14px;
}

/* {//datepopup} */


/* Sidebar */
.date-sidebar {
border-right: 1px solid #eee;
padding: 12px;
background-color: #fff;
}

.date-sidebar ul {
list-style: none;
padding: 0;
margin: 0;
}

.date-sidebar li {
padding: 8px 12px;
cursor: pointer;
font-size: 14px;
border-radius: 4px;
}

.date-sidebar li:hover {
background-color: #f0f0f0;
}

.date-sidebar .custom-range {
font-weight: bold;
color: #007bff;
}

/* Calendar Panel */
.calendar-panel {
padding: 12px;
background-color: #fff;
}

/* Footer below calendar */
.calendar-footer {
display: flex;
justify-content: space-between;
margin-top: 12px;
gap: 10px;
}

.calendar-footer input {
border: 1px solid #ccc;
padding: 4px 8px;
border-radius: 4px;
font-size: 13px;
width: 130px;
}

.calendar-footer button {
padding: 6px 12px;
font-size: 13px;
border: none;
border-radius: 4px;
cursor: pointer;
}

.calendar-footer button:first-of-type {
background-color: #eee;
}

.calendar-footer button:last-of-type {
background-color: #007bff;
color: white;
}

.popup-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.4); /* dimmed background */
display: flex;
align-items: center;
justify-content: center;
z-index: 1000;
}

.date-picker-wrapper {
margin-left: 15rem !important;
background-color: white;
padding: 20px;
display: flex;
gap: 20px;
border-radius: 10px;
max-width: 90%;
z-index: 1001;
}

.date-sidebar ul {
list-style: none;
padding: 0;
}

.stock-cross {
cursor: pointer;
float: right;
font-size: 20px;
}

.calendar-footer {
display: flex;
gap: 10px;
margin-top: 10px;
}