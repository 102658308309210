.head {
  margin-left: 3vw !important;
}
.icon-div {
  margin-left: 40px;
}
.delete-icon {
  font-size: 19px;
  cursor: pointer;
}
.edit-icon {
  font-size: 20px;
  cursor: pointer;
  margin-right: 5px;
}
.adding-input {
  border: none;
  width: 160px !important;
  border-radius: 1px;
  padding: 10px !important;
}
.ott-td{
  padding: 10px;
}
.table-head {
  text-align: center;
  padding: 13px !important;
}
.role-td {
  text-align: center;
  padding: 5px;
}
.add-btn {
  margin-left: 60% !important;
}

.table-border {
  margin-top: 60px !important;
  

  width: 340px !important;
}

.role-head {
  background-color: #257abf !important ;
  color: white !important;
}

@media only screen and (max-width: 800px) {
  .add-btn {
    margin-right:-60%;
  }
  .table-border {
    margin-top: 60px !important;
    margin-left: 6px !important;
    width:60% !important;
  }
}
