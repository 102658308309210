.edit
{
    margin-left: 690px;
}

.border-container {
    border: 2px solid grey;
    padding: 20px; 
    text-align: center; 
    height:auto;
    width:90% !important;
    margin-left: 60px;
    margin-bottom: 50px !important;
  }
  .border-container1
  {
    margin-top: 30px;
    width:200px !important;
    margin-left: 60px;
  }
.form-scrolled
{
   overflow-y: scroll !important;
}
  .custom
  {
    padding: 11px;
  }

  .box{
    width: 105% !important;
    height:150px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-left: 30px;
    background-color:white;
    border: 2px solid rgb(158, 155, 155);
    border-radius: 5px;
  }

  .inputfield{
    /* width:105% !important; */
  }

  
  /* input {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-left: 30px;
    background-color:#E8ECEF;
    border: 2px solid rgb(158, 155, 155);
    border-radius: 5px;
  } */
  /* label{
margin-top:10px;
margin-bottom: 10px;
 

   input[type="date"].field 
  {
    margin-top: 10px;
    margin-bottom: 15px;
    padding-left: 30px;
    
    
  }

  /* .inputfield{
    width:100%;


  }  */

  .dropdown {
    padding: 10px;
    width:220px;
    margin-top: 10px;

  }

  

  

  .online
  {
    background-color: rgb(252, 189, 72);
    padding: 10px;
  }
.offline
{
background-color:rgb(67, 170, 243) ;
padding: 10px;

}

.active
{
    background-color: rgb(114, 201, 145);
    padding: 10px;
}

.inactive
{
    background-color: rgb(252, 189, 72);
    padding: 10px;
}

.suspended
{
   

    background-color: rgb(252, 189, 72);
    padding: 10px;

}

.disable
{
    background-color: rgb(252, 189, 72);
    padding: 10px;
}




.dropdown {
    position: relative;
    display: inline-block;
  }
  
.dropbtn
  {
width:200px;





  }

  
  

  .list
  {
    border:2px solid rgb(62, 52, 52);
    width:225px;
    background-color: rgb(156, 247, 247);

  }

  .listoption
  {
    list-style: none;
  }

  

  .darkblue 
  {
    background-color: #306CFF;
    height:40px;
    border: none;
  }

  .onlineback
  {
    background-color:#fee792 ;
    color:black;
    height:100px;
    
  }

  

  .offlineback
  {
    color:black;
    height:100px;
    background-color: #7DABFF;
  }


  .activeback
  {
    background-color: #B0E9BE;
    height:50px;
    color:#2C693A;
  }

  .inactiveback
  {
    background-color: #BDBDBD;
    height:50px;
    color:#4D4D4D;
  }

  .suspendedback
  {
    background-color: #CFA87B;
    height:50px;
    color:#663815;
  }


  .disableback{
    background-color:#FE8C8D;
    height:50px;
    color:#A73535;
  }


  .actbtn
  {
    height:100px;
  }

  .submit
  {
    margin-right: 55px;
    
  }



  /* @media only screen and (max-width: 500px){
    .rowdown{

    margin-right: 120px;

    }

    
   

    .border-container
    {
      height:90%;
    }


    
  }

  .select
  {
    border: 2px solid rgb(158, 155, 155);
  }


  .uprow
  {
    margin-top: -10px;
  }


  @media only screen and (max-width: 994px)
  {
    .form-input{
      width: 88% !important;
    }
  }  

  @media only screen and (max-width: 750px)
  {
    .border-container
    {
      height: 2100px !important;
    }
  } */

  .margin-right
{
margin-left:0px !important;
}




.inputfield
{
  width:90% !important;
}