* {
    margin: 0;
    padding: 0;
  }
  
  .n-password-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .n-password {
    background-color: #257abf;
     
    height: 100vh;
  }
   
   
   
  .n-password-col {
    width: 50%;
    background-color: white;
    height: 450px;
    border-radius: 20px;
    padding: 30px;
    position: relative;
    margin: auto;
    top:120px;
  }
  
  .n-password-box {
    display: flex !important;
  }
  .backward {
  
    margin-top: 22px;
    font-size: 24px;
  }
  .n-password-txt {
    color: #257ABF;
    font-size: 26px;
    margin-top: -30px;
    margin-left: 25%;
    font-weight: 700;
  }
  .n-password-form {
    margin-left: 70px;
    margin-top: 35px !important;
  }
  .n-password-form label {
    margin-top: 10px;
  }
  .n-password-form input {
    margin-top: 10px;
    border: none !important;
    background-color: #363740;
    width: 75%;
    padding: 9px !important;
    border-radius: 7px;
    color: white;
  }
  .n-password-form a {
    text-decoration: none;
    margin-left: 198px;
    color: #257ABF;
    font-size: 12.5px;
    font-weight: 600;
  }
  .show-hide-1{
    position: absolute;
    color: rgb(195, 191, 191);
    margin-top:  3% !important;
    left: 78% !important; 
  }
  .show-hide-2{
    position: absolute;
    color: rgb(195, 191, 191);
    margin-top:  3% !important;
    left: 78% !important; 
  }
  .n-password-form button {
    margin-left: 28%;
    border: none;
    width: 140px;
    height: 35px;
    padding: 5px;
    border-radius: 10px;
    background-color:#257ABF;
    color: white;
    margin-top: 35px;
    font-weight: 600;
    cursor: pointer;
  }
@media (max-width:800px){
  .n-password-col{
    width: 85%;
  }
}
  /* @media screen and (max-width: 1178px)
  {
    .n-password-col{
        height: 450px !important;
    }
  }


  @media screen and (max-width: 1070px)
  {
    .n-password-col
    {
        height: 450px !important;
    }
  }

  @media screen and (max-width: 782px)
  {
    .n-password-col
    {
        height: 500px !important;
    }
  }

  @media screen and (max-width: 719px)
  {
    .n-password-form label {
        margin-left: -50px!important;

    }

    .n-password-form input    {
        margin-left: -50px!important;

    }

    .n-password-form button 
    {
        margin-left: -50px!important;
    }
  } */