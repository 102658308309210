.border-container {
  border: 2px solid grey;
  padding: 20px;
  text-align: center;
  height: auto;
  width: 95%;
}
.border-container1 {
  margin-top: 30px;
  width: 150px;
}
.border-container-stock {
  border: 2px solid grey;
  padding: 20px;
  text-align: center;
  height: auto;
  width: 95%;
}
.stock-edit {
  width: 100px !important;
}
.plus-blue-btn {
  width: 50px !important;
}
.plusstock-label {
  font-size: 17px;
}
.border-container-plus {
  border: 1px solid grey;
  border-radius: 0.5rem;
  background-color: #eef7ff;
  padding: 20px;
  text-align: center;
  height: auto;
  width: 100%;
}
