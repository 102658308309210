


.custom-checkbox{
    margin-top: 20px;
    margin-bottom: 30px;
}

  .checkbox-complaint-margin{

    margin-left: 20px !important;


  }

  .checkbox-complaint-top{
    margin-top: 25px !important;
  }
  .open-button{
    width:40%;
    background-color:#fbdc6e ;
    padding: 7px;
    color:rgb(94, 94, 14);
  }

  

.border-container {
  border: 2px solid grey;
  padding: 20px; 
  text-align: center; 
  height:auto;
  width:95%;
}
.border-container1
{
  margin-top: 30px;
  width:150px;
}
.form-scrolled
{
 overflow-y: scroll !important;
}
.custom
{
  padding: 11px;
}

.box{
  width: 80% !important;
  height:150px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 30px;
  background-color:white;
  border: 2px solid rgb(158, 155, 155);
  border-radius: 5px;
}






.field 
{
  margin-top: 10px;
  margin-bottom: 15px;
  padding-left: 30px;
  
  width:80% !important;
}

input[type=date]
{
  width:100%;
}


.dropdown {
  padding: 10px;
  width:220px;
  margin-top: 10px;

}





.online
{
  background-color: rgb(252, 189, 72);
  padding: 10px;
}
.offline
{
background-color:rgb(67, 170, 243) ;
padding: 10px;

}

.active
{
  background-color: rgb(114, 201, 145);
  padding: 10px;
}

.inactive
{
  background-color: rgb(252, 189, 72);
  padding: 10px;
}

.suspended
{
 

  background-color: rgb(252, 189, 72);
  padding: 10px;

}

.disable
{
  background-color: rgb(252, 189, 72);
  padding: 10px;
}




.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn
{
width:200px;





}






.custom-file-input::-webkit-file-upload-button{

  visibility: hidden;


}


.custom-file-input{
  color:transparent;
}

.list
{
  border:2px solid rgb(62, 52, 52);
  width:225px;
  background-color: rgb(156, 247, 247);

}

.listoption
{
  list-style: none;
}



.darkblue 
{
  background-color: #306CFF;
  height:40px;
  border: none;
}

.onlineback
{
  background-color:#fee792 ;
  color:black;
  height:100px;
  
}



.offlineback
{
  color:black;
  height:100px;
  background-color: #7DABFF;
}


.activeback
{
  background-color: #B0E9BE;
  height:50px;
  color:#2C693A;
}

.inactiveback
{
  background-color: #BDBDBD;
  height:50px;
  color:#4D4D4D;
}

.suspendedback
{
  background-color: #CFA87B;
  height:50px;
  color:#663815;
}


.disableback{
  background-color:#FE8C8D;
  height:50px;
  color:#A73535;
}


.actbtn
{
  height:100px;
}

.submit
{
  margin-right: 55px;
  
}


/* 
@media only screen and (max-width: 500px){
  .rowdown{

  margin-right: 120px;

  }

  .inputfield{
    width:100% !important;
  }
 

  .border-container
  {
    height:90%;
  }


  
} */

.select
{
  border: 2px solid rgb(158, 155, 155);
}


.uprow
{
  margin-top: -10px;
}


/* @media only screen and (max-width: 994px)
{
  .form-input{
    width: 88% !important;
  }
}  

@media only screen and (max-width: 750px)
{
  .border-container
  {
    height: 2100px !important;
  }
} */

.margin-right
{
margin-left:0px !important;}



.inputfield
{
width:92% !important;
}




.converted-form
{
  width:72%;
}

.plandetails-form
{
  margin-top: 12% !important;
}

/* Ensure modal is centered and background is dim */
.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dim effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Box */
.modal1 {
  background: white;
  padding: 20px;
  width: 60%;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* Fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Buttons */
.button-group1 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.cancel-btn1 {
  background: gray;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
}

.ok-btn1 {
  background: blue;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.open-btn1 {
  padding: 10px 20px;
  background: blue;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="text"].overlay2{
  width:80%;
}

input[type="tel"].overlay2{
  width:80%;
}

.overlay1-converted {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dim effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Box */
.modal1-converted {
  background:#EEF7FF;
  padding: 20px;
  width: 35%;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}


.converted-button{
  width:40%;
  background-color:  #b0e9bd;
  padding: 7px;
  color: #2c693a;

}
.address-enquiry{
width:85% !important;
}
.open-button{
  width:40%;
  background-color:#fbdc6e ;
  padding: 7px;
}

.converted-textbox{
  width:100%;
  background-color: white;
  height: 200px;
  padding: 20px;
}

.uploadbtn-install{
  margin-top: 35px !important;
  text-align: end;
}


.date-update-textbox label{
  margin-right: 35%;
}

.date-update-textbox input[type="date"]{
  margin-right: 40%;
}



.close-btn1 {
  position: absolute;
  margin-left: 15% !important;
  background: none;
  border: none;
  font-size: 10px;
  cursor: pointer;
  color: #333;
  transition: 0.3s;
  font-weight: bolder;
}


.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
}

/* Upload Button */
.upload-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  color: #333;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  font-size: 14px;
  transition: 0.3s;
}

.upload-btn:hover {
  background: white;
}

/* Upload Icon */
.upload-icon {
  font-size: 18px;
}

.file-name {
  margin-top: 5px;
  font-size: 12px;
color:black;  }

.lead-id-row
{
border:2px solid #a7a7a7;
width:93.5%;
border-radius: 5px;
height:50px;


}

.lead-start-date{
padding-left: 30px;


}

.paragraph-lead-id{
padding-top: 8px;
}

.plan-details-container-border-complaint{

border: 2px solid  #a7a7a7 ;
width:50%;
box-shadow: 2px 2px #a7a7a7 ;



}

p .plan-details-container-border{
font-size: 1px;
}

.verification-photo-upload{

width:50%;
margin-left: 25%;
border: 2px solid  #a7a7a7;
margin-top: 5%;


}

.verification-timeago{
color: #a7a7a7;
}

.verificatin-photo-background{
background-color: #dddada;
}

.threedot-popup{



width:90%;

margin-top: 10%;
border:2px solid  #a7a7a7;

text-align: center;
animation: fadeIn 0.3s ease-in-out;
}

.cross-symbol-verification{
margin-right: 10%;
}

.fiber-button-workassign{
width: 13% !important;
background-color: #4D4D4D;
border-radius: 5%;
padding: 10px;
color:white;

}




.tabs-container-workassign {
margin-left: 7%;
}

.fiber-workassign{
/* margin-left: 10%; */

}

.fiberteam-container{

margin-left: -2% !important;

}
.fiber-team-assignedby{
background-color: #d3d0d0;

}


.staff-name-fiberteam{
background-color: #EEF7FF;
width:100%;
height:90px;
text-align: center;
border: 2px solid   #a7a7a7 ;


}

.fiber-team-name{
width:80%;
border: 2px solid   #a7a7a7 ;
background-color: white;
height:40px;
text-align: center;
margin-top: 25px;
margin-left: 50px;
border-radius: 5px;

}

.fiber-team-fiber-container{

background-color: #EEF7FF;
width:100%;
height:50px;
text-align: center;
border: 2px solid   #a7a7a7 ;

}

.fiberteam-container{
margin-right: 7%;
}

.increment-btn-fiber{

width:30px;
height:30px;
margin-top: 40px;
border: none;
color:white;

}




.fiber-team-photos{
width: 100%;
height:250px;
border: 2px solid #a7a7a7;
background-color: #EEF7FF ;

}

.fiber-team-upload-photo{
margin-left: 10%;
margin-right: 10%;

}

.feedback-installation{

border: 2px solid #a7a7a7;
background-color: #EEF7FF ;
height: 250px;

border-radius: 5px;

}

.feedback-installation-container {
margin-left: 20%;
margin-right: 20%;

}

.feedback-collectedby{
margin-top: 20%;

}

.plan-details-container-border-complaint-payment{

border: 2px solid  #a7a7a7 ;
width:70%;
box-shadow: 2px 2px #a7a7a7 ;


}

.add-payment{
margin-top: 30%;
width:60%;
height:40px;
border: none;
border-radius: 5px;

}

.green-ruppes-payment{
width:180px !important;
height:40px;
border-radius: 5px;
text-align: center;
background-color: #50d06e;
border: 2px solid green;
padding: 10px;
}
.total-row td{
border: none !important;
font-weight: bold;
background: white; /* Ensure it matches the table background */
text-align: right;
padding-right: 15px; 
border-bottom: none !important;
}

.payment-photo-container{
/* width:100%; */
border-radius: 5px;
border: 2px solid
 #a7a7a7;
 margin-top: 60px;

}

.due-amount-container {
display: flex;
justify-content: flex-end;
align-items: center;
margin-top: 10px;
font-weight: bold;
margin-right: 60px;
}

.due-label {
color: #007bff; /* Blue color for label */
margin-right: 90px;
font-size: 16px;
}

.due-amount {
color: #dc3545; /* Red color for due amount */
font-size: 18px;
}

.overlay-payment{
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5); /* Dim effect */
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}
.modal-payment{
background:#EEF7FF;
padding: 20px;
width: 45%;
border-radius: 8px;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
/* text-align: center; */
animation: fadeIn 0.3s ease-in-out;
}

.payment-select-option{
margin-top: -50px !important;
}



.upload-container-payment {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
padding: 12px;
border: 2px dashed #ccc;
border-radius: 8px;
background-color: #f8f9fa;
cursor: pointer;
transition: background 0.3s ease-in-out;
}

.upload-container-payment:hover {
background-color: #f1f1f1;
}

.upload-icon-payment {
display: flex;
align-items: center;
gap: 8px;
color: #6c757d;
font-size: 14px;
font-weight: 500;
}

.upload-input-payment {
display: none; /* Hide default file input */
}

.payment-reference{
width:90% !important;
margin-left: 10px;

}
.payment-reference::placeholder{
text-align: center;
}

.modal-advance-payment{
background:#EEF7FF;
padding: 20px;
width: 45%;
border-radius: 8px;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
/* text-align: center; */
animation: fadeIn 0.3s ease-in-out;

}

.overlay-advance-payment{

position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5); /* Dim effect */
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;

}


.popupAdvancePayment {
position: absolute;
top: 50px;
left: 0;
width: 220px;
padding: 10px;
background: white;
border: 1px solid #ccc;
border-radius: 5px;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
animation: fadeIn 0.3s ease-in-out;
}

.complaint-type{
  width:90% !important;
}