.right-column-dashboard{
  margin-bottom: 50px !important;
   

}


.dashboard-adds
{
    background-color:  #eef7ff;
    border:2px solid #a7a7a7;
}

.text-statistics
{
color:#1E78C2;
}

.card-enquiry{
    background-color: #ccb7ff;
  color: #5b4591;
  margin-top: 0;
  padding: 10px;
}




.card {
    width: 350px;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    font-family: Arial, sans-serif;
  }
  
  .card-header {
    background-color:#ccb7ff !important;
    color: #5b4591;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
  }
  
  .card-content {
    padding: 5px;
    background-color: #F9F9F9;
  }
  
  .stat-item {
    display: flex;
    justify-content:space-between;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  
  .stat-item:last-child {
    border-bottom: none;
  }

  .stat-item span:last-child {
    padding-right: 40px; /* Adjust this value as needed */
    font-weight: bold;
  }
 

  .stat-item:first-child,
  .stat-item:nth-child(2),
  .stat-item:nth-child(3), /* Follow Up (Pending) */
.stat-item:nth-child(4), /* Interested */
.stat-item:nth-child(5), /* Not Interested */
.stat-item:nth-child(6), /* Converted This Month */
.stat-item:nth-child(7) {
    padding-left: 10px; /* Adjust as needed */
  }

 .card-header-install{
    background-color: #b0e9bd;
    color: #2c693a;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;

  }

  .card-header-complaints{
    background-color: #ffa5ee;
  color: #a73535;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;

  }

  .card-header-dues{
    background-color: #7dabff;
  color: #2e5caf;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;

  }
  .card-header-recovery{
    background-color: #cfa87b;
    color: #663815;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;

  }
  .card-header-task{
    background-color: #f07c7c;
  color: #a73535;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;

  }

  .update-bg
  {
    background: #565656;
    color: white;
    padding: 13px;
    width:100%;
    
  }

  @media only screen and (max-width: 1350px) {
   .card{
    width:300px !important;
   }
  }
  

  
  @media only screen and (max-width: 1180px) {
    .card{
     width:250px !important;
    }
   }
   @media only screen and (max-width: 890px) {
    .card{
     width:220px !important;
    }
   }

   .threedots-left{
    margin-right: 10px;
   }

   .name-update
   {
    display: flex;
    justify-content: space-between;
   }
   .name-update p
   {
    font-size: 16px !important;
   }

   .update-border-container{
    background-color: white;
   }
   .image-bg-update{
    background-color: #333;
    width:370px;
    height: 330px;
   }

   .enter-comments input[type=text]
   {
width: 65%;
margin-left: 20px;
margin-right: 20px;
height:40px;
border-radius: 20px;

margin-top: 10px;
background-color: white;

   }

   .logo-update
   {
    border-radius: 50%;
   }

   .chat-background
   {
    background-color: #EEF7FF;
    margin-right:2px;
    border-radius: 10px;
   }

   .thumbsup-update 
   {
    margin-top: 10px !important;
    align-items: center;
    position: relative;
   }
   .xx
   {
    position: absolute;
    top:-1px;
    color: gray;
   }