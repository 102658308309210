.roles-container {
    width: 17% !important;
    height:500px;
  }
  
  .roles-box {
    background: white;
    /* padding: 2px; */
    border-radius: 5px;
    /* height:500px !important; */
  }
  
  .roles-title {
    background: #565656;
    color: white;
    padding: 12px;
    text-align: center;
    font-size: 18px;
    
  }
  
  .role-item {
    padding: 5px;
    background: #92bcdd;
    margin: 5px 0;
    border-radius: 5px;
    cursor: pointer;
    font-size: small;
    text-align: center;
    color:white;
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: medium;
}
.roles-top{
  
  margin-top: 20px;
}


@media only screen and (max-width: 580px) {
  .roles-container{
    width:80% !important;
    height:auto;
  }
}