.header-container {
  background: #fff;
}

.arrow
{
    background-color: #1E78C2;
    color:white;
    border-radius: 50%;
    width:35px;
    height:35px;
    aspect-ratio: 1/1;
    border: none;
    margin-right: 20px;

}

.inputsearch
{
    width: 200px;
    height:40px;
    margin-left:10px;
    margin-right: 8px;
    background-color: white;
    border :2px solid #CCCCCC;
    border-radius: 5px;
    padding-left: 10px;
}

.iconarr
{
    margin-top: -5px;
    margin-left:px;
    
}


.logo-s
{
    border: none;
    border-radius: 50%;
    background-color: #373741;
    color:white;
    width:40px;
    height:40px;
    margin-left: 20px;
    padding-bottom:5px ;
}

.hori
{
border: 2px solid ;
margin-top: -2px;

width:100%;
margin-left: 0px;
}

.icon-back
{
    background-image: url('../Icon/Search.svg');
    background-repeat: no-repeat;
    background-position: 10px 10px ;
    

}

.header
{
  font-size: 12vw;
}


.popup-header {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: white;
    padding: 10px;
    border: 2px solid black;
    border-radius: 5px;
    z-index: 1000 !important; 
    margin-top: 5% !important;
    
    
  }

  

  .user{
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 10px;


  }

  .fontsize
  {
    font-size: 12px;
  }


  .colon
  {
    margin-bottom: 10px;

    margin-left:5px;
    margin-top: 20px;
  }
  
  .userid



  {

    margin-left: -60px;
    margin-top: 20px;
  }
  

  .colon1{
    margin-left: 5px;
    margin-top: 20px;
  }


  .userid1
  {
    margin-left:-100px;
    
  }
.logopop
{
  display: inline;
  border-radius: 50%;
}

.hori1
{
  width:240px;
  margin-left: -10px;
}
  .rowsize
  {
margin-top: -15px;
  }

  .logoutbtn
  {
    border: none;
    background-color: white;
    margin-left:80px;
  }


  
  .header-main {
    padding: 10px;
    background: #fff;
  }
  
  .header-right {
    gap: 10px; /* Space between elements */
  }
  
  .search-box {
    position: relative;
    display: flex;
  }
  
  .search-box input {
    padding: 5px 35px 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 180px;
  }
  
  
  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: gray;
  }
  
  .select-box {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .profile-circle {
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1;
    background: #1E78C2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .notification {
    position: relative;
    font-size: 19px;
  }
  
  .notification-dot {
    width: 7px;
    height: 7px;
    background: red;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }
  
  
  .popup-header {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: white;
    padding: 10px;
    border: 2px solid black;
    border-radius: 5px;
    z-index: 1000 !important; 
    margin-top: 5% !important;
    
    .popup-content {
      color:black;
      padding: 15px; 
      background-color:white;
      border-radius: 5px;
      width:250px;
     
    }
    
  }
  .abc{
    display: flex;
 justify-content: space-between;
    align-items: center;
  }
  .xyz{
    display: flex;
    justify-content:flex-end;
    gap: 12px;
    align-items: center;
  }
  div.headLast
  {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  /* ____________________________________________ */
  @media screen and (max-width: 768px) {
    .profile-circle,
    .notification {
      margin-top: 10px; /* Small gap */
    }
  
    .search-icon {
      right: 5px; /* Align Search Icon */
    }
  }

  @media screen and (max-width: 520px) {
    
    .arrow
          {
              /* display: none; */

          }
          div.profile-circle
          {
            /* display: none; */
          }
          div.notification
          {
            /* display: none; */
          }
          .abc{
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .xyz{
            display: flex;
            flex-direction: column;
            /* border: 2px solid red; */
            align-items: flex-start;
          }
          .inputsearch
          {
           
              margin-left:0px;   
            
          }
     
        
       
   
         
  }