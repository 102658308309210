.section-title {
	margin-bottom: 30px;
	text-align: center;

	h2 {

		color: $heading-color;
		font-family: $heading-font;
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		margin-bottom: 0;
	}

	h3 {
		font-family: $heading-font;
		margin-bottom: 0;
		color: $heading-color;
		font-size: 40px;
		font-weight: 700;
		line-height: 50px;
		@include media-query(767px) {
			font-size: 30px;
			line-height: 40px;
		}

	}

	p {
		font-size: 18px;
	}

	&.s2 {
		span {
			background: #2979FE;
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

		}
	}

}