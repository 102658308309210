.tabs {
    margin-left: 5%;
    margin-right: 5%; 
     
   }
   
   .tabs button {
     padding: 10px 15px;
     border: none;
     cursor: pointer;
     background-color: #257abf;
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
     width:100%;
     color:white;
     
 
   }
   
   .tabs button:hover {
     background-color:rgb(91, 169, 196);
   }
   
   .tab-content {
     padding: 15px;
     border: 1px solid #ccc;
     background: white;
     width:90%;
     margin-left: 5%;
     margin-right: 5% !important;
   }
   .assign-btn
   {
     background-color: #257abf;
     color:white;
     width: 100px;
   height: 40px;
 
   padding: 5px;
 
   border-radius: 5px;
   border: none;
   }
   .overlay3 {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(0, 0, 0, 0.5); /* Dim effect */
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 1000;
   }
   
   /* Modal Box */
   .modal3 {
     background: white;
     padding: 20px;
     width: 80%;
     margin-left: 15%;
     border-radius: 8px;
     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
     text-align: center;
     animation: fadeIn 0.3s ease-in-out;
   }
   
   /* Fade-in effect */
   @keyframes fadeIn {
     from {
       opacity: 0;
       transform: scale(0.9);
     }
     to {
       opacity: 1;
       transform: scale(1);
     }
   }
   
   /* Buttons */
   .button-group3 {
     margin-top: 20px;
     display: flex;
     justify-content: space-between;
   }
 
 
   .cancel-btn3 {
     background: gray;
     color: white;
     padding: 8px 12px;
     border: none;
     border-radius: 4px;
     cursor: pointer;
   }
   
   .ok-btn3 {
     background: blue;
     color: white;
     padding: 8px 12px;
     border: none;
     border-radius: 4px;
     cursor: pointer;
   }
 
   .inputfield-assign{
     width:90% !important;
     height:150px;
   }