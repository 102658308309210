 

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}
 
.login {
  background-color: #257abf;
   
  height: 100vh;
}
 
 
 
.login-col {
  width: 50%;
  background-color: white;
  height: auto;
  border-radius: 20px;
  padding: 30px;
  position: relative;
  margin: auto;
  top:120px;
}

.login-txt {
  padding: 3%;
  text-align: center; /* Center text */
  color: #257abf;
  font-weight: 700;
}

.login-form {
  margin-left: auto;
  margin-right: auto;
  margin-top: -5px;
  width: 80%; /* Adjust width to your needs */
}

.login-form label {
  margin-top: 2px;
}

.login-form input {
  margin-top: 10px;
  border: none;
  background-color: #363740;
  width: 100%;
  padding: 9px;
  border-radius: 7px;
  color: white;
}

.f-password {
  text-decoration: none;
  display: block;
  text-align: end;
  color: #257abf;
  font-size: 12.5px;
  font-weight: 600;
  padding: 9px;
  margin-top: 0px;
  margin-left: 74%;
  background-color: white;
  border: none;
}

.log-btn{
  border: none;
  width: 30%;
  padding: 7px;
  border-radius: 5px;
  background-color: #257abf;
  color: white;
  margin-top: 24px;
  font-weight: 700;
  cursor: pointer; 
  margin-left: 35%;
}

.show-hide {
  position: absolute;
  color: rgb(195, 191, 191);
  margin-top: 20px !important;
  margin-left: -25px !important;
}
@media (max-width:800px){
  .login-col{
    width: 80%;
  }
  .f-password{
    margin-left: 53%;
  }
}