
.add-container{
  margin-bottom: 50px;
}
  .add-staff{
    width:150px !important;
    color:white;
    height:45px;
   text-align: center;
   margin-left:5% ;
  }
  .btn-upload {
    width: 100%;
    border: 2px solid #adb5bd;
    color: #adb5bd;
   
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background-image: url("../Icon/Upload.svg");
    background-repeat: no-repeat;
    background-size: 190px 18px;
  }
  
  /* .btn-upload:hover {
    background-color: #f8f9fa;
  } */
  .file-upload .custom-text {
    margin-top: auto;  
  }
  
  .file-upload::-webkit-file-upload-button {
    visibility: hidden;
    font-size: 2px;
    padding-top: 20px;
  }
  .form-input-extent{
     width: 90% !important;
  }


  .add-staff-container{
    width:90%;
    border: 2px solid #adb5bd ;
    margin-left:5%;
  }


  .add-staff-form{
    margin-left: 5%;
    margin-top: 5%;
  }

  .staffedit-icon{
   
    font-size: 17px;
     
      cursor: pointer;
      margin-right:5px !important;
      
  }
  .staff-show-hide{
    
    color: rgb(195, 191, 191);
    margin-top: 20px !important;
    margin-left: -25px !important;
  }
 .black-btn-re{
 width: 85px !important;
  }