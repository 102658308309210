* {
  margin: 0;
  padding: 0;
}

.register-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.register {
  background-color: #257abf;
  height: 100vh;
}

.register-col {
  width: 50%;
  background-color: white;
  height: auto;
  border-radius: 20px;
  padding: 30px;
  position: relative;
  margin: auto;
  top: 120px;
}
.register-box {
  display: flex;
}
.register-txt {
  color: #257abf;
  font-size: 33px;
  margin-left: 26%;
  margin-top: 22px;
}
.backward {
  margin-left: 2vw;
  margin-top: 22px;
  font-size: 30px !important;
}
.register-form {
  margin-left: 80px;
  margin-top: 8px;
}
.register-form label {
  margin-top: 2px;
}
.register-form input {
  margin-top: 10px;
  color: white;
  background-color: #363740;
  width: 72%;
  padding: 9px !important;
  border-radius: 7px;
  color: white;
  border: none;
}
.register-form a {
  text-decoration: none;
  margin-left: 198px;
  color: #257abf;
  font-size: 12.5px;
  font-weight: 600;
}
.btn {
  padding: 5px;
}
.register-form button {
  cursor: pointer;
  margin-left: 95%;
  border: none;
  width: 130px;
  height: 35px;
  padding: 5px;
  border-radius: 15px;
  background-color: #257abf;
  color: white;
  margin-top: 20px;
  font-weight: 600;
}
.show-hide-register {
  position: absolute;
  color: rgb(195, 191, 191);
  margin-top: 20px !important;
  margin-left: -25px !important;
}
